import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { generalSettingData } from "../../utils/Helper";
import {
  ORDER_CREATE_URL,
  PRODUCT_DETAILS_URL,
  PRODUCT_IMAGE_URL,
  USER_PROFILE_URL,
} from "../../utils/Constant";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "../../layouts/WebsiteLayout";
import "./PayNow.css";
import PayPalButton from "../Paypal/Paypal";

const PayNow = () => {
  const imagePath =
    process.env.PUBLIC_URL + "/assets/dist/img/about-breadcrumb-bg.png";
  const [isFullPayment, setIsFullPayment] = useState(true); // Add state variable for full payment

  const [errorMessage, setErrorMessage] = useState("");
  const [productDetailsData, setProductDetailsData] = useState({});
  const [userProfileData, setUserProfileData] = useState({});
  const [isPaymentPopupOpen, setPaymentPopupOpen] = useState(false);
  const [partialAmount, setPartialAmount] = useState();
  const [isPartialAmountAdded, setIsPartialAmountAdded] = useState(false);
  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState({});
  const [generalS, setGeneralS] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.token);

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const [categoryData] = await Promise.all([
          generalSettingData(authToken),
        ]);
        setGeneralS(categoryData);
      } catch (error) {}
    };
    fetchLists();
  }, []);
  //console.log("generalS>>>>>>>>>>>>>", generalS);

  const showToast = (loading, message, type) => {
    toast[type](
      <div>
        {loading} {message}
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // milliseconds
      }
    );
  };

  async function fetchProducts() {
    try {
      setLoading(true);
      const response = await fetch(`${PRODUCT_DETAILS_URL}/${params.id}`);
      const jsonData = await response.json();
      if (jsonData.status === "success" && jsonData.data) {
        setProductDetailsData(jsonData.data);

        setLoading(false);
      } else {
        console.error("API response indicates failure:", jsonData);
      }
    } catch (err) {
      console.error("Error fetching product details:", err);
    }
  }
  const fetchUserData = async () => {
    if (authToken) {
      try {
        const response = await fetch(USER_PROFILE_URL, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken.token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setUserProfileData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  useEffect(() => {
    fetchProducts();
    if (authToken) {
      fetchUserData();
    }
  }, [params.id]);

  function togglePartialAmount(isPartial) {
    setIsPartialAmountAdded(false);
    setPartialAmount(Math.ceil(partialPremiumCount));
    var partialAmountContainer = document.getElementById(
      "partial-amount-container"
    );
    if (isPartial) {
      partialAmountContainer.style.display = "block";
      setIsPartialPayment(true);
      setIsFullPayment(false); // Uncheck full payment when partial payment is selected
    } else {
      partialAmountContainer.style.display = "none";
      setIsPartialPayment(false);
      setIsFullPayment(true); // Check full payment when partial payment is not selected
    }
  }

  //console.log("productDetailsData", productDetailsData);

  let isBidWon = productDetailsData?.isBidWon;
  let isOrdered = productDetailsData?.isOrdered;
  // let globalPremiumType = "percentage";
  // let globalPremiumFee = globalPremiumType === "percentage" ? 10 : 1000;
  // let globalMinAmount = 100;
  // let globalMaxAmount = 500;

  let globalPremiumType = generalS?.data?.premiumType;
  let globalPremiumFee = generalS?.data?.premiumAmount;
  let globalMinAmount = generalS?.data?.minPremiumAmount;
  let globalMaxAmount = generalS?.data?.maxPremiumAmount;

  let percentagePremium;
  let premiumCommissionName;
  let premiumCommissionValue;

  let premiumCount;

  if (productDetailsData?.isPremiumEnabled) {
    if (productDetailsData?.premiumType === "flat") {
      premiumCommissionName = "flat";
      premiumCommissionValue = productDetailsData?.premiumAmount;

      premiumCount =
        productDetailsData?.buyNowPrice + productDetailsData?.premiumAmount;
    } else if (productDetailsData?.premiumType === "percentage") {
      let percentagePremium =
        (productDetailsData?.buyNowPrice * productDetailsData?.premiumAmount) /
        100; // % of buyNowPrice
      premiumCommissionName = "%";
      premiumCommissionValue = productDetailsData?.premiumAmount;

      if (percentagePremium < productDetailsData?.minPremiumAmount) {
        premiumCount = productDetailsData?.minPremiumAmount;
      } else if (percentagePremium > productDetailsData?.maxPremiumAmount) {
        premiumCount = productDetailsData?.maxPremiumAmount;
      } else {
        premiumCount = percentagePremium;
      }
    }
  } else {
    // When isPremiumEnabled is false, use global values
    if (globalPremiumType === "flat") {
      premiumCount = productDetailsData?.buyNowPrice + globalPremiumFee;
      premiumCommissionName = "flat";
      premiumCommissionValue = globalPremiumFee;
    } else if (globalPremiumType === "percentage") {
      percentagePremium =
        (productDetailsData?.buyNowPrice * globalPremiumFee) / 100; // 10% of buyNowPrice
      premiumCommissionName = "%";
      premiumCommissionValue = globalPremiumFee;
      if (percentagePremium < globalMinAmount) {
        premiumCount = globalMinAmount;
      } else if (percentagePremium > globalMaxAmount) {
        premiumCount = globalMaxAmount;
      } else {
        premiumCount = percentagePremium;
      }
    }
  }

  // TOTAL BUY NOW AMOUNT------------

  let finalAmount =
    premiumCommissionName == "flat"
      ? premiumCommissionValue + productDetailsData?.buyNowPrice
      : premiumCount + productDetailsData?.buyNowPrice;

  const closePaymentPopup = () => {
    setPaymentPopupOpen(false);
  };

  let partialPremiumCount = (finalAmount * 10) / 100;
  const handleChange = (e) => {
    //console.log(e.target.value);
    setPartialAmount(e.target.value);
    //console.log(partialPremiumCount, premiumCount);
    if (partialAmount < partialPremiumCount) {
      setIsPartialAmountAdded(true);
    } else {
      setIsPartialAmountAdded(false);
    }
  };
  //console.log(partialAmount, isPartialAmountAdded);

  const payNow = () => {
    //console.log("Pay Now Clicked", partialPremiumCount);
    if (isPartialPayment && partialAmount < partialPremiumCount) {
      // Set error message
      setErrorMessage(
        "Partial amount should be greater than or equal to " +
          partialPremiumCount
      );
    } else {
      // Open the payment popup
      setPaymentPopupOpen(true);
      // Reset error message if it was previously set
      setErrorMessage("");
    }
  };
  const handleSuccess = (details) => {
    //console.log("Paypal Response::::::", details, premiumCommissionName);
    let orderType = isPartialPayment ? "partial" : "full";
    let premiumAmount =
      premiumCommissionName == "flat" ? premiumCommissionValue : premiumCount;
    let paidAmount = isPartialPayment ? partialAmount : finalAmount;
    let pendingAmount = isPartialPayment ? finalAmount - partialAmount : 0;
    const objData = {
      orderType: orderType,
      premiumAmount: premiumAmount,
      paidAmount: paidAmount,
      pendingAmount: pendingAmount,
      paymentTime:
        details?.purchase_units[0]?.payments.captures[0]?.create_time,
      paypalResponse: details,
      premiumType: premiumCommissionName == "flat" ? "flat" : "percentage",
      totalAmount: finalAmount,
      customerId: authToken.user_id,
      productId: params.id,
      orderAmount: productDetailsData?.buyNowPrice,
      orderTime: details?.purchase_units[0]?.payments.captures[0]?.create_time,
      paymentStatus:
        details?.status != null ? details.status.toLowerCase() : "",
      transactionId: details?.purchase_units[0]?.payments.captures[0]?.id,
      paymentMethod: "paypal",
      orderStatus: "processing",
    };

    //console.log("objDataobjDataobjData", objData);
    setPaymentDetails(objData);
    fetchData(objData);
    fetchProducts();
  };

  const fetchData = async (objData) => {
    try {
      setLoading(true);
      const data = objData;
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken.token}`,
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(ORDER_CREATE_URL, config);
      const result = await response.json();
      if (response.ok) {
        setLoading(false);
        setPaymentPopupOpen(false);
        showToast(true, result.message, "success");
        setTimeout(() => {
          navigate("/my-account#orders");
        }, 500);
      } else {
        showToast(true, result.error, "error");
        console.error(
          "Error sending data to the API:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <DashboardLayout>
        <div
          className="breadcrumb breadcrumb-style-one mb-0"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 1, 13, 0.8), rgba(0, 1, 13, 0.8)), url(${imagePath})`,
          }}
        >
          <div className="container">
            <div className="col-lg-12 text-center">
              <h1 className="breadcrumb-title">Pay Now</h1>
              {/* <ul className="d-flex justify-content-center breadcrumb-items">
                  <li className="breadcrumb-item">
                    <i className="bi bi-house-door" /> <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Pay Now</li>
                </ul> */}
            </div>
          </div>
        </div>
        <div className="row mt-3 justify-content-center">
          {/* <div className="col-xl-8">
              <div className="card">
                <div className="card-body">
                  <ol className="activity-checkout mb-0 px-4 mt-3">
                    <li className="checkout-item-1">
                      <div className="feed-item-list">
                        <div>
                          <h5 className="font-size-14 mb-3">
                            <b>Billing Info</b>
                          </h5>

                          <div className="mb-3">
                            <form>
                              <div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="billing-name"
                                      >
                                        Full Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="billing-name"
                                        placeholder="Enter name"
                                        value={
                                          userProfileData.firstName +
                                          " " +
                                          userProfileData.lastName
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="billing-email-address"
                                      >
                                        Email Address
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="billing-email-address"
                                        placeholder="Enter email"
                                        value={userProfileData.email}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="billing-phone"
                                      >
                                        Phone No.
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="billing-phone"
                                        placeholder="Enter Phone no."
                                        value={userProfileData.phone}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="billing-address"
                                      >
                                        Full Address
                                      </label>
                                      <textarea
                                        className="form-control"
                                        id="billing-address"
                                        rows="5"
                                        placeholder="Enter full address"
                                        value={userProfileData.address}
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="billing-city"
                                      >
                                        City
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="billing-city"
                                        placeholder="Enter City"
                                        // value={userProfileData.firstName}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="mb-3">
                                      <label
                                        className="form-label"
                                        for="zip-code"
                                      >
                                        Zip / Postal code
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="zip-code"
                                        placeholder="Enter Postal code"
                                        value={userProfileData.pinCode}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div> */}
          <div className="col-xl-6">
            <div className="widget-form">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="form-group">
                  <a href={`/products/${params.id}`}>
                    <button
                      className="widget-btn back-button"
                      style={{
                        lineHeight: "1",
                        padding: "16px",
                        fontSize: "18px",
                      }}
                    >
                      <i
                        className="bx bx-left-arrow-circle"
                        style={{
                          marginRight: "5px",
                          verticalAlign: "middle",
                        }}
                      ></i>{" "}
                      Back
                    </button>
                  </a>
                </div>
              </div>
            </div>

            {!isOrdered && !isBidWon ? (
              <div className="card checkout-order-summary">
                <div className="card-body">
                  {loading ? (
                    <div
                      className="col-lg-12 col-sm-12 main-heading"
                      style={{ textAlign: "center" }}
                    >
                      <div
                        className="spinner-border"
                        style={{
                          width: "4rem",
                          height: "4rem",
                          color: "#c21557",
                          textAlign: "center",
                        }}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <h5 className="font-size-14 mb-3">
                          <b>Product Details :</b>
                        </h5>
                        <table className="table table-centered mb-0 table-nowrap">
                          <thead>
                            <tr>
                              <th
                                className="border-top-0"
                                style={{ width: "110px" }}
                                scope="col"
                              >
                                Product
                              </th>
                              <th className="border-top-0" scope="col">
                                Product Desc
                              </th>
                              <th className="border-top-0" scope="col">
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">
                                <img
                                  src={`${PRODUCT_IMAGE_URL}/${productDetailsData?.featuredImage}`}
                                  alt="product-img"
                                  title="product-img"
                                  className="avatar-lg rounded"
                                />
                              </td>
                              <td>
                                <h5 className="font-size-16 text-truncate">
                                  <a href="#" className="text-dark">
                                    <b>{productDetailsData?.productName}</b>
                                  </a>
                                </h5>

                               
                              </td>
                              <td>${productDetailsData?.buyNowPrice}</td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p className="font-size-14 m-0">
                                  <b>
                                    Treasuretrad Commission (
                                    {premiumCommissionName == "flat"
                                      ? premiumCommissionName
                                      : premiumCommissionValue + "%"}
                                    ) :
                                  </b>
                                </p>
                              </td>
                              <td>
                                $
                                {premiumCommissionName == "flat"
                                  ? premiumCommissionValue
                                  : premiumCount}
                              </td>
                            </tr>
                            <tr className="bg-light">
                              <td colspan="2">
                                <h5 className="font-size-14 m-0">
                                  <b>Total:</b>
                                </h5>
                              </td>
                              <td>
                                <h5 className="font-size-14 m-0">
                                  <b>${finalAmount}</b>
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <h5 className="font-size-14 mb-3 mt-3">
                          <b>Payment method :</b>
                        </h5>
                        <div className="row">
                          <div className="col-lg-6 col-sm-6 col-xs-6">
                            <div>
                              <label className="card-radio-label">
                                <input
                                  type="radio"
                                  name="pay-method"
                                  id="pay-methodoption2"
                                  className="card-radio-input"
                                  onClick={() => togglePartialAmount(false)}
                                  checked={isFullPayment} // Set checked state
                                />
                                <span className="card-radio py-3 text-center text-truncate">
                                  <i className="bx bxl-paypal d-block h2 mb-3"></i>
                                  Full Payment
                                </span>
                              </label>
                            </div>
                          </div>

                          <div className="col-lg-6 col-sm-6 col-xs-6">
                            <div data-bs-toggle="collapse">
                              <label className="card-radio-label">
                                <input
                                  type="radio"
                                  name="pay-method"
                                  id="pay-methodoption1"
                                  className="card-radio-input"
                                  onClick={() => togglePartialAmount(true)}
                                />
                                <span className="card-radio py-3 text-center text-truncate">
                                  <i className="bx bx-money d-block h2 mb-3"></i>
                                  Partial Payment
                                </span>
                              </label>
                            </div>
                          </div>
                          <div
                            className="col-lg-12 col-sm-12"
                            id="partial-amount-container"
                            style={{ display: "none" }}
                          >
                            <div className="mb-3 mt-3">
                              <label
                                className="form-label"
                                for="partial-amount"
                              >
                                <b>Partial Amount</b>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="partial-amount"
                                placeholder="Enter Partial Amount"
                                required
                                value={partialAmount}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 col-sm-12">
                            <div class="widget-form">
                              <div
                                style={{ width: "100%", "margin-top": "20px" }}
                              >
                                <div class="form-group">
                                  {errorMessage && (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {errorMessage}
                                    </div>
                                  )}
                                  <button
                                    className="widget-btn"
                                    id="payment-button"
                                    onClick={payNow}
                                    style={{
                                      width: "100%",
                                      "margin-top": "20px",
                                    }}
                                  >
                                    Pay Now $
                                    {isPartialPayment
                                      ? partialAmount
                                      : finalAmount}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isPaymentPopupOpen && (
                            <div className="payment-popup">
                              <div className="payment-content">
                                <button
                                  className="close-button"
                                  onClick={closePaymentPopup}
                                >
                                  ✕
                                </button>
                                <div className="payment-amount">
                                  Buy Now Price: $
                                  {isPartialPayment
                                    ? partialAmount
                                    : finalAmount}
                                </div>
                                <br></br>
                                <PayPalButton
                                  totalAmount={
                                    isPartialPayment
                                      ? partialAmount
                                      : finalAmount
                                  }
                                  onSuccess={handleSuccess}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="card checkout-order-summary">
                <div className="card-body">
                  <div className="table-responsive"></div>
                  <div>
                    <table className="table table-centered mb-0 table-nowrap">
                      <thead>
                        <tr>
                          <th
                            className="border-top-0"
                            style={{ width: "110px" }}
                            scope="col"
                          >
                            Product
                          </th>
                          <th className="border-top-0" scope="col">
                            Product Desc
                          </th>
                          <th className="border-top-0" scope="col">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row">
                            <img
                              src={`${PRODUCT_IMAGE_URL}/${productDetailsData?.featuredImage}`}
                              alt="product-img"
                              title="product-img"
                              className="avatar-lg rounded"
                            />
                          </td>
                          <td>
                            <h5 className="font-size-16 text-truncate">
                              <a href="#" className="text-dark">
                                <b>{productDetailsData?.productName}</b>
                              </a>
                            </h5>

                            <p className="text-muted font-size-12 mb-0 mt-1">
                              ${productDetailsData?.buyNowPrice} x 1
                            </p>
                          </td>
                          <td>${productDetailsData?.buyNowPrice}</td>
                        </tr>
                      </tbody>
                    </table>
                    <h5
                      className="font-size-14 mb-3 mt-3"
                      style={{ "text-align": "center" }}
                    >
                      <b>Product Not Available for Purchase or Auction</b>
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default PayNow;
