import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layouts/WebsiteLayout";
import { toast } from "react-toastify";
import { REGISTER_URL } from "../../utils/Constant";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";

const SignupPage = () => {
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [imagePreview, setImagePreview] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [acceptFee, setAcceptFee] = useState(false);

  const navigate = useNavigate();
  const authToken = useSelector((state) => state.token);

  useEffect(() => {
    if (authToken) {
      navigate("/my-account");
    }
  }, [authToken, navigate]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    profileImage: null,
  });

  const areAllFieldsFilled = () => {
    for (const key in formData) {
      if (!formData[key]) {
        return false;
      }
    }
    return true;
  };

  //const navigate = useNavigate();

  const showToast = (loading, message, type) => {
    toast[type](
      <div>
        {loading} {message}
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // milliseconds
      }
    );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const passwordsMatch = () => {
    return formData.password === formData.confirmPassword;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Exclude password and confirmPassword fields from immediate form update
    if (name !== "password" && name !== "confirmPassword") {
      setFormData({ ...formData, [name]: (name === 'email' || name === 'username') ? value.toLowerCase() : value });
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: (name === 'email' || name === 'username') ? value.toLowerCase() : value}));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImagePreview(file ? URL.createObjectURL(file) : null);
    setFormData({ ...formData, profileImage: file });
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    if (!passwordsMatch()) {
      showToast(true, "Passwords do not match", "error");
      return;
    }
if ( !acceptFee) {
      showToast(true, "Please accept the terms", "error");
      return;
    }

    //console.log("formData>>>>>>>>>>",formData)
    let data = new FormData();

    data.append("username", formData.username);
    data.append("email", formData.email);
    data.append("password", formData.password);
    data.append("firstName", formData.firstName);
    data.append("lastName", formData.lastName);
    data.append("phone", formData.phone);
    data.append("image", formData.profileImage);


    if (!areAllFieldsFilled()) {
      showToast(true, "Please fill all the details", "error");
    } else {
      setEmailSent(false);
      try {
        setLoading(true); // Show spinner

        const response = await fetch(REGISTER_URL, {
          method: "POST",
          maxBodyLength: Infinity,
          headers: {},
          body: data,
        });
        const result = await response.json();
        if (response.ok) {
          setLoading(false); // Show spinner
          setEmailSent(true);
          showToast(true, result.msg, "success");
          // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });
          
        } else {
          setLoading(false); // Show spinner
          showToast(true, result.error, "error");
          console.error("Registration failed:", result.message);
        }
      } catch (error) {
        setLoading(false); // Show spinner
        showToast(true, error.message, "error");
        console.error("Error during registration:", error.message);
      }
    }
   
  };


  return (
    <DashboardLayout>
      <section className="register-section pt-110">
        {loading && (
          <div className="overlay">
            <div
              class="spinner-border text-danger"
              style={{ width: "5rem", height: "5rem" }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-12">
              <div className="register-btn-group">
                <Link to="/register" className="register-btn">
                  Register
                </Link>
                <Link to="/login" className="login-btn">
                  Login
                </Link>
              </div>
            </div>
          </div>
          <div className="row gy-4">
           
            <div className="col-sm-8 mx-auto">
              <div class="right-form-area">
                {emailSent ? (
                  <>
                    <h3>Registration Confirmation</h3>
                    <div className="emailSent">
                      <p>
                        <img
                        alt=""
                          style={{ height: "90px" }}
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/dist/img/check-mark.png"
                          }
                        />
                      </p>
                      <br />
                      <p style={{ color: "green", fontWeight: "bold" }}>
                        You are registered Successfully!
                      </p>
                      <br />
                      <p>
                        Activation Email sent to your email ({formData.email}),
                        Please check your inbox or spam folder.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <h3>Register Your Account</h3>
                    <form class="w-100" onSubmit={handleRegistration}>
                      {/* <p className="registratioFee"><span className="required">* </span>$5 registration fee required</p> */}
                      <div class="form-row">
                        <div class="form-inner">
                          <label>
                            First Name <span class="error">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div class="form-inner">
                          <label>
                            Last Name <span class="error">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-inner">
                          <label>
                            Email Address <span class="error">*</span>
                          </label>
                          <input
                            type="email"
                            placeholder="Your email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-inner">
                          <label>
                            User Name <span class="error">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="User Name"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div class="form-inner">
                          <label>
                            Phone No. <span class="error">*</span>
                          </label>
                          <input
                            type="tel"
                            placeholder="Your phone no."
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-inner">
                          <label>
                            Password <span class="error">*</span>
                          </label>
                          <input
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                          <i
                            class={`fas ${
                              passwordVisible ? "fa-eye-slash" : "fa-eye"
                            }`}
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                        <div class="form-inner">
                          <label>
                            Confirm Password <span class="error">*</span>
                          </label>
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                          />
                          <i
                            class={`fas ${
                              confirmPasswordVisible ? "fa-eye-slash" : "fa-eye"
                            }`}
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        </div>
                      </div>
                      
                      <div class="form-group">
                        <label for="profileImage">
                          Profile Picture <span class="error">*</span>
                        </label>
                        <input
                          type="file"
                          class="form-control-file"
                          id="profileImage"
                          name="profileImage"
                          onChange={handleFileChange}
                          required
                        />
                        {/* Display image preview */}
                        {imagePreview && (
                          <div>
                            <h5>Image Preview</h5>
                            <img
                              src={imagePreview}
                              alt="Preview"
                              style={{ maxWidth: "100%", height: "150px" }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label>
                          <input
                            type="checkbox"
                            checked={acceptFee}
                            onChange={() => setAcceptFee(!acceptFee)}
                            
                          />
                          <span className="registratioFee">I accept the $5 (One time Subscription Fee) have to pay for all Buy Now and Bid Now on products.</span>
                        </label>
                      </div>
                      <button type="submit" class="account-btn">
                        Sign Up
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};

export default SignupPage;
