// ErrorBoundary.js - Error Boundary Component
import React, { Component } from 'react';
import NotFoundPage from './pages/NotFoundPage'; // Import the 404 page component

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <NotFoundPage />; // Render 404 page if an error occurs
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
