import React from "react";
import { convertIsoToCustomFormat } from "../../utils/Helper";

const ProductsOverview = (productDetailsData) => {
  productDetailsData = productDetailsData.productDetailsData;

  // const formattedDateString = (originalDate) => {
  //   return originalDate.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //   });
  // };

  return (
    <div className="single-widget">
      <h5 className="wdget-title">Product Overview</h5>
      <ul className="widget-list">
        <li className="auction-bid">
          
            <span><h5>Price :</h5></span>
            <span>
              <h5>${productDetailsData.openingPrice}</h5>
            </span>
         
        </li>

        <li>
          <span>Category :</span>
          <span>
            {productDetailsData.categoryId &&
              productDetailsData.categoryId.categoryName}
          </span>
        </li>
        <li>
          <span>Item Condition :</span>
          <span>{productDetailsData.productCondition}</span>
        </li>
        {/* <li>
                    <span>Bid Start From :</span>
                    <span>
                        {formattedDateString(
                            new Date(productDetailsData.bidStartDate)
                        )}
                    </span>
                </li> */}
        <li>
          <span>Ending On</span>
          <span>
            {convertIsoToCustomFormat(productDetailsData?.bidEndDate)}
            {/* {formattedDateString(new Date(productDetailsData.bidEndDate))} */}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default ProductsOverview;
