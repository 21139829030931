import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapContainer = ({ lat, lng }) => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 0, // Replace with the actual latitude
    lng: 0, // Replace with the actual longitude
  };

  return (
    <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
      <GoogleMap mapContainerStyle={mapStyles} zoom={8} center={{ lat, lng }}>
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </LoadScript>
  );
};

export const ProductMisSection = ({ productDetailsData }) => {
  const [activeTab, setActiveTab] = useState("description");
  const [locationData, setLocationData] = useState({
    lat: 0, // Replace with the actual latitude
    lng: 0, // Replace with the actual longitude
  });

  const handleTabClick = (tab) => {
    if (tab === "location") {
      // Set the location data (latitude and longitude) based on your requirements
      setLocationData({
        lat: 37.7749, // Example latitude (replace with actual data)
        lng: -122.4194, // Example longitude (replace with actual data)
      });
    }
    setActiveTab(tab);
  };

  return (
    <section className="best-auction-section">
      {/* <div className="item-describe-area">
                <ul className="nav nav-pills d-flex justify-content-center gap-sm-4 gap-1">
                    <li>
                    <button
                            className={`nav-link custom-btn ${activeTab === 'description' ? 'active' : ''}`}
                           
                        >
                            Description
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link custom-btn ${activeTab === 'description' ? 'active' : ''}`}
                            onClick={() => handleTabClick('description')}
                        >
                            Description
                        </button>
                    </li> 
                    <li className="nav-item">
                        <button
                            className={`nav-link custom-btn ${activeTab === 'location' ? 'active' : ''}`}
                            onClick={() => handleTabClick('location')}
                        >
                            Location
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link custom-btn ${activeTab === 'video' ? 'active' : ''}`}
                            onClick={() => handleTabClick('video')}
                        >
                            About Video
                        </button>
                    </li> 
                </ul>
                <div className="tab-content">
                    <div className={`tab-pane ${activeTab === 'description' ? 'show active' : ''}`}>
                        <div className="describe-content">
                            <p className="mb-15">{productDetailsData.productDescription}</p>
                        </div>
                    </div>
                    <div className={`tab-pane ${activeTab === 'location' ? 'show active' : ''}`}>
                        <MapContainer
                            lat={locationData.lat}
                            lng={locationData.lng}/>
                    </div>
                    <div className={`tab-pane ${activeTab === 'video' ? 'show active' : ''}`}>
                        <div className="about-video">
                            <div className="wrapper">
                                <div className="video-pluse">
                                    <a
                                        href="https://www.youtube.com/watch?v=u31qwQUeGuM"
                                        className="video-frame video-icon"
                                    >
                                        <i className="bx bx-play" />
                                       
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="single-widget mb-3">
        <h5 className="wdget-title">Product Description</h5>

        <p className="paragraph">{productDetailsData.productDescription}</p>
      </div>
    </section>
  );
};
