import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import Login from './pages/Auth/Login';
import SignupPage from './pages/Auth/Signup';
import MyAccount from './pages/MyAccount/MyAccount';
import { Provider } from 'react-redux';
import store, { persistor } from './utils/store';
import { PersistGate } from 'redux-persist/integration/react';
import About from './pages/About/About';
import Faq from './pages/Faq/Faq';
import Privacy from './pages/Privacy/Privacy';
import Contact from './pages/Contact/Contact';
import Auction from './pages/AllAuction/Auction';
import HowToBid from './pages/HowToBid/HowToBid';
import PayNow from './pages/PayNow/PayNow';
import ForgetPassword from './pages/Auth/ForgetPassword';
import UpdatePassword from './pages/Auth/UpdatePassword';
import ActivateAccount from './pages/Auth/ActivateAccount';
import ErrorBoundary from './ErrorBoundary'; 
import NotFoundPage from './pages/NotFoundPage';
import Page from './pages/MetaTags/MetaTags';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary> 
          <Page />
          <div className="App">
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/products/:id" element={<ProductDetails />} />
                
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<SignupPage />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/philosophy" element={<About />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/privacy" element={<Privacy />} />
                {/* <Route path="/contact-us" element={<Contact />} /> */}
                <Route path="/expired-auctions" element={<Auction />} />
                <Route path="how-to-bid" element={<HowToBid />} />
                <Route path="/product/pay-now/:id" element={<PayNow />} />
                <Route path="/forget/password" element={<ForgetPassword />} />
                <Route path="/forget/password/update/:activationCode" element={<UpdatePassword />} />
                <Route path="/activate/account/:activationcode" element={<ActivateAccount />} />
                <Route path="*" element={<NotFoundPage />} />
                <Route path="/page-not-found" element={<NotFoundPage />} />
              </Routes>
            </Router>
          </div>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default App;
