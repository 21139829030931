import React, { useEffect, useState } from "react";
import { CREDIT_CARD_URL, CUSTOMER_PROFILE_URL, VOID_CREDIT_CARD_PAYMENT_URL, VERIFY_CREDIT_CARD_URL } from "../../utils/Constant";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setToken } from "../../utils/reducers/authSlice";

const PaymentMethod = ({ onCCDetailsSubmit }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.token);
  const [loading, setLoading] = useState(false);
  const [creditCardNumber, setCreditCardNumber] = useState("");
  const [creditCardExpiryMonth, setCreditCardExpiryMonth] = useState("");
  const [creditCardExpiryYear, setCreditCardExpiryYear] = useState("");
  const [creditCardCVV, setCreditCardCVV] = useState("");
  const [creditCardType, setCreditCardType] = useState("");
  const [creditCardFirstNameOnCard, setCreditCardFirstNameOnCard] = useState("");
  const [creditCardLastNameOnCard, setCreditCardLastNameOnCard] = useState("");
  const [authorizationId, setAuthorizationId] = useState(""); // State to store authorizationId

  const showToast = (message, type) => {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };

  const fetchUserData = async () => {
    setLoading(true);
    if (authToken) {
      try {
        const response = await fetch(CUSTOMER_PROFILE_URL, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken.token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCreditCardNumber(data.data.creditCardNumber || "");
        setCreditCardExpiryMonth(data.data.creditCardExpiryMonth || "");
        setCreditCardExpiryYear(data.data.creditCardExpiryYear || "");
        setCreditCardCVV(data.data.creditCardCVV || "");
        setCreditCardType(data.data.creditCardType || "");
        setCreditCardFirstNameOnCard(data.data.creditCardFirstNameOnCard || "");
        setCreditCardLastNameOnCard(data.data.creditCardLastNameOnCard || "");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchUserData();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      number: creditCardNumber,
      expire_month: creditCardExpiryMonth,
      expire_year: creditCardExpiryYear,
      cvv2: creditCardCVV,
      type: creditCardType,
      first_name: creditCardFirstNameOnCard,
      last_name: creditCardLastNameOnCard,
      block_amount: 5
    };

    // Call the verifyCreditCard function here
    verifyCreditCard(data);
  };

 

  const verifyCreditCard = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(VERIFY_CREDIT_CARD_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken.token}`,
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      //console.log(result);
      if (result.success) {
        showToast("Credit Card verified successfully", "success");
        //console.log("result.authorizationId",result.authorizationId)
        setAuthorizationId(result.authorizationId); // Store authorizationId in state
        handleCreditCardUpdate(data);
      } else {
        showToast("Credit Card verification failed", "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error verifying credit card:", error);
    }
  };

  const handleCreditCardUpdate = async (data) => {
    try {

      const response = await fetch(CREDIT_CARD_URL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken.token}`,
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      const updatedToken = {
        ...authToken,
        isCCFilled: true,
      };
      
      if (response.ok) {
        
        if (voidPayment()) { // Check if voidPayment is successful before proceeding
          setLoading(false);
          dispatch(setToken(updatedToken));
          //showToast(result.message, "success");
          fetchUserData();
          onCCDetailsSubmit();
        } else {
          showToast("Failed to void", "error");
        }
      } else {
        showToast(result.error, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      setLoading(false);
    }
  };
  const voidPayment = async () => {
    setLoading(true);

    //console.log("authorizationId::::::::::::::",authorizationId)
    try {
      const response = await fetch(VOID_CREDIT_CARD_PAYMENT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken.token}`,
        },
        body: JSON.stringify({
          authorizationId: authorizationId, // Use the stored authorizationId
        }),
      });
      const result = await response.json();
      //setLoading(false);
      //showToast("CC Verification amount unholded", "success");
      return response.ok;
    } catch (error) {
      setLoading(false);
      console.error("Error voiding payment:", error);
      return false;
    }
  };
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  const generateYears = (count) => {
    const currentYear = getCurrentYear();
    const years = [];
    for (let i = 0; i < count; i++) {
      years.push(currentYear + i);
    }
    return years;
  };

  const creditCards = [
    { type: "Visa", key: "visa", usageRank: 1 },
    { type: "Mastercard", key: "mastercard", usageRank: 2 },
    { type: "American Express", key: "amex", usageRank: 3 },
    { type: "Discover", key: "discover", usageRank: 4 },
    { type: "JCB", key: "jcb", usageRank: 5 },
    { type: "Diners Club", key: "diners", usageRank: 6 },
    { type: "UnionPay", key: "unionpay", usageRank: 7 },
  ];
  const sortedCreditCards = creditCards.sort(
    (a, b) => a.usageRank - b.usageRank
  );

  const renderCreditCardTypes = () => {
    return sortedCreditCards.map((card, index) => (
      <option key={index} value={card.key}>
        {card.type}
      </option>
    ));
  };

  const renderExpiryMonths = () => {
    const months = Array.from({ length: 12 }, (_, i) =>
      String(i + 1).padStart(2, "0")
    );
    return months.map((month, index) => (
      <option key={index} value={month}>
        {month}
      </option>
    ));
  };

  const renderExpiryYears = () => {
    const years = generateYears(10);
    return years.map((year, index) => (
      <option key={index} value={year}>
        {year}
      </option>
    ));
  };

  return (
    <>
      <h3 className="paymentMethod">Payment Method (Credit Card Details)</h3>
      {loading && (
        <div className="overlay">
          <div
            className="spinner-border text-danger"
            style={{ width: "5rem", height: "5rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <>
        <form onSubmit={handleSubmit}>
          <div className="form-row mt-3">
            <div className="form-inner">
              <label>
                First Name <span className="error">*</span>
              </label>
              <input
                type="text"
                placeholder="First Name"
                value={creditCardFirstNameOnCard}
                onChange={(e) => setCreditCardFirstNameOnCard(e.target.value)}
                required
              />
            </div>
            <div className="form-inner">
              <label>
                Last Name <span className="error">*</span>
              </label>
              <input
                type="text"
                placeholder="Last Name"
                value={creditCardLastNameOnCard}
                onChange={(e) => setCreditCardLastNameOnCard(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="form-row mt-3">
            <div className="form-inner">
              <label htmlFor="cc">
                Credit Card <span className="error">*</span>
              </label>
              <input
                id="cc"
                type="text"
                name="creditCardNumber"
                placeholder="---- ---- ---- ----"
                value={creditCardNumber}
                onChange={(e) => setCreditCardNumber(e.target.value)}
                maxLength={16}
                required
              />
            </div>
            <div className="form-inner">
              <label htmlFor="creditCardType">
                Card Type <span className="error">*</span>
              </label>
              <select
                id="creditCardType"
                name="creditCardType"
                value={creditCardType}
                onChange={(e) => setCreditCardType(e.target.value)}
                required
              >
                {renderCreditCardTypes()}
              </select>
            </div>
          </div>

          <div className="form-row mt-3">
            <div className="form-inner">
              <label>
                Card Expiry Month <span className="error">*</span>
              </label>
              <select
                value={creditCardExpiryMonth}
                onChange={(e) => setCreditCardExpiryMonth(e.target.value)}
                required
              >
                {renderExpiryMonths()}
              </select>
            </div>
            <div className="form-inner">
              <label>
                Card Expiry Year <span className="error">*</span>
              </label>
              <select
                value={creditCardExpiryYear}
                onChange={(e) => setCreditCardExpiryYear(e.target.value)}
                required
              >
                {renderExpiryYears()}
              </select>
            </div>
            <div className="form-inner">
              <label htmlFor="cvv">
                Enter CVV<span className="error">*</span>
              </label>
              <input
                id="cvv"
                type="text"
                name="creditCardCVV"
                maxLength={3}
                value={creditCardCVV}
                onChange={(e) => setCreditCardCVV(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="button-box">
            <button className="btn default-btn" type="submit">
              Update
            </button>
         
          </div>
        </form>
      </>
    </>
  );
};

export default PaymentMethod;
