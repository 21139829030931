import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { generalSettingData } from "../../utils/Helper";

const PayPalButton = ({ totalAmount, onSuccess }) => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [generalS, setGeneralS] = useState([]);
  const authToken = useSelector((state) => state.token);

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const [categoryData] = await Promise.all([
          generalSettingData(authToken),
        ]);
        setGeneralS(categoryData);
      } catch (error) {
        // Handle error if needed
      }
    };
    fetchLists();
  }, []);

  const getClientID = () => {
    if (generalS?.data?.paypal?.mode === "live") {
      return generalS?.data?.paypal?.client_id_live;
    } else {
      return generalS?.data?.paypal?.client_id_sandbox;
    }
  };

  const clientID = getClientID();

  const handleApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      setPaymentDetails(details);

      //console.log("Paypal details", details);

      if (details.status === "COMPLETED") {
        // Call the onSuccess callback to handle redirection
        onSuccess(details);
      }
    });
  };

  return (
    <>
      {clientID && (
        <PayPalScriptProvider
          options={{
            "client-id": clientID,
          }}
        >
          <div>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: totalAmount, // Set the total amount from your cart
                      },
                    },
                  ],
                });
              }}
              onApprove={handleApprove}
            />
          </div>
        </PayPalScriptProvider>
      )}
    </>
  );
};

export default PayPalButton;
