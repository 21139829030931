import React from "react";
import DashboardLayout from "../../layouts/WebsiteLayout";

const HowToBid = () => {
  const imagePath =
    process.env.PUBLIC_URL + "/assets/dist/img/about-breadcrumb-bg.png";
  return (
    <DashboardLayout>
      <div
        className="breadcrumb breadcrumb-style-one mb-0"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 1, 13, 0.8), rgba(0, 1, 13, 0.8)), url(${imagePath})`,
        }}
      >
        <div className="container">
          <div className="col-lg-12 text-center">
            <h1 className="breadcrumb-title">HOW TO BID</h1>
            <ul className="d-flex justify-content-center breadcrumb-items">
              <li className="breadcrumb-item">
                <i className="bi bi-house-door" /> <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">How To Bid</li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="container howtobid"><br></br>
        <p style={{ fontWeight: "bold",color: "#c21557", fontSize: "16px !important", fontStyle: "italic" }}>
          Welcome to the Treasuretrad Auctions!
        </p>
        
        <p>To get started: </p>
        <ul>
        <li>1. Register to Bid </li>
        <li>2. Pay 5$ one time fee </li>
        <li>3. Win any item</li>
        </ul>
        
        
        
        <p>
          All bidders must be registered prior to bidding with a Valid payment
          option on file.
        </p>
        
        <p>
          All items will be assessed a minimum buyer fee of 10% at the
          conclusion of their individual sale. Therefore, if a Bidder wins 3
          items, at 100$ each item, at the end of the completed auction, he/she
          will have 30$ automatically deducted from their payment on file. These
          fees are non refundable and needed to keep this platform operational.
        </p>
        
        <p>Only buyer fees are collected through the payment on file.</p>
        
        <p
          style={{ color: "#c21557", fontWeight: "bold", fontStyle: "italic" }}
        >
          The remaining balance must be in cash at time of pickup. All sales are
          final. No refunds and no returns.
        </p>
        
        <p>
          Items must be picked up within 48 hours after auction or additional
          fees may apply at 30$ per day. If you fail to pay or pickup your item
          within 1 week we will re auction your item.
        </p>
        
        <p>
          Pickup is by appointment only. Each item has an address, phone number
          and or an email. In the event the seller is not being reasonable with
          pickup accommodations please reach out to our team via text or email
          so we can assist.<br />
          <span style={{ color: "#c21557", fontWeight: "bold" }}>
            Phone <a href="tel:+7604014871">(760) 401 4871</a>
          </span>
        </p>
        
        <p>Please notify the location before pickup.</p>
        
        <p>
          ID is needed for pick up with confirmation that you have paid your
          buyers fees (confirmation sent via email) A Screen shot will do!
        </p>
        
        <p>Lets Get Started!</p>
        
        {/* <p>
          Anyone can <a href="/register">Register</a> for the next auction.
        </p> */}
        
      </div>
    </DashboardLayout>
  );
};

export default HowToBid;
