import React from 'react'

function Footer() {
  return (
    <footer className="footer-area footer-style-two">
      <div className="container">
        <div className="footer-main">
          <div className="row d-flex justify-content-center gy-5">
            {/* <div className="col-lg-4 col-md-6 col-sm-10 col-11">
              <div className="footer-widget">
                <div className="footer-disc">
                  <a href="/">
                    <img
                      style={{ height: 65 }}
                      src={process.env.PUBLIC_URL + "/assets/dist/img/cropped-mototrad.png"}
                      alt="logo"
                    />
                  </a>
                  
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 col-sm-10 col-11 d-flex justify-content-lg-center justify-content-start">
              <div className="footer-widget">
                <h5 className="widget-title">Quick Links</h5>
                <ul className="widget-links">
                  <li>
                    <a href="/my-account">My Account</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                
                </ul>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6 col-sm-10 col-11">
              <div className="footer-widget">
                <h5 className="widget-title">Contact Us</h5>
                <ul className="footer-contact-list">
                  <li>
                    <div className="contact-icon">
                      <i className="bi bi-telephone-plus" />
                    </div>
                    <div className="contact-list">
                      <a href="tel:+7604014871">(760) 401 4871</a>
                    </div>
                  </li>
                  <li>
                    <div className="contact-icon">
                      <i className="bi bi-envelope" />
                    </div>
                    <div className="contact-list">
                    <a href="mailto:info@treasuretrad.com">
                  <span>info@treasuretrad.com</span>
                </a>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright row align-items-center">
          <div className="col-lg-12 col-md-12 order-1 order-md-0 text-center text-md-start  mt-md-0">
            <p>
            Copyright &copy; 2024 Treasuretrad
            </p>
          </div>
          
        </div>
      </div>
    </footer>

  )
}

export default Footer