import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
//import { useNavigate } from "react-router-dom";
//import { useDispatch } from "react-redux";
import DashboardLayout from "../../layouts/WebsiteLayout";
import { FORGET_URL } from "../../utils/Constant";
//import { setRandomPassword } from "../../utils/reducers/authSlice";

const ForgetPassword = () => {
  const [formData, setFormData] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  //const dispatch = useDispatch();
  //const navigate = useNavigate();

  const showToast = (loading, message, type) => {
    toast[type](
      <div>
        {loading} {message}
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // milliseconds
      }
    );
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //console.log("formData", formData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      showToast(true, "Please fill in all required fields", "error");
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      setEmailSent(false);
      showToast(true, "In Progress. Please wait..", "info");
      const response = await fetch(FORGET_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        setLoading(false);
        setEmailSent(true);
        showToast(true, result.message, "success");
      } else {
        showToast(true, result.error, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setLoading(false);
    }
  };
  return (
    <DashboardLayout>
      <section className="register-section pt-110">
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-12">
              <div className="register-btn-group">
                <Link className="login-btn style2">Forget Password</Link>
                {/* <Link to="/login" className="login-btn style2">
                                    Login
                                </Link> */}
              </div>
            </div>
          </div>
          <div className="row gy-4">
            {/* <div className="col-lg-6">
              <div className="register-left-area style2">
                <h3>Hello Again</h3>
                <p>Welcome Back You’ve Been Missed</p>
                <Link to="/" className="transparent-btn">
                  Back Home
                </Link>
              </div>
            </div> */}
            <div className="col-sm-8 mx-auto">
              <div className="right-form-area">
                <h3>Forget Password?</h3>
                {emailSent ? (
                  <div className="emailSent">
                    <p>
                      Email sent to your email ({formData.email}),
                      <br />
                      <br /> Please check your inbox or spam folder.
                    </p>
                  </div>
                ) : (
                  <form className="w-100" onSubmit={handleSubmit}>
                    <div className="form-inner">
                      <label>
                        Your Email Address<span className="error">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <p>
                      We’ll send a verification code to this email if it matches
                      an existing account.
                    </p>
                    <br />
                    {/* <div className="form-inner">
                                        <label>Password <span className="error">*</span></label>
                                        <input
                                            type="password"
                                            id="password"
                                            placeholder="Password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                        />
                                        <i className="bi bi-eye-slash" id="togglePassword" />
                                    </div> */}
                    {/* <div className="form-inner d-flex justify-content-between flex-wrap">
                                        <Link to="#" className="forgot-pass">
                                            Forgotten Password
                                        </Link>
                                    </div> */}

                    <div className="form-inner d-flex justify-content-between flex-wrap">
                      <Link to="/login" className="forgot-pass">
                        Want to Login ?
                      </Link>
                    </div>
                    <button className="account-btn" disabled={loading}>
                      {/* Reset Password */}
                      {loading ? "Sending Email....." : "Next"}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default ForgetPassword;
