import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/WebsiteLayout";
import {
  PRODUCT_PAST_AUCTIONS_LIST_URL,
  PRODUCT_IMAGE_URL,
} from "../../utils/Constant";
import SkeletonCard from "../../components/SkeletonCard";

const Home = () => {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);
  //const imagePath = process.env.PUBLIC_URL + "/assets/dist/img/about-breadcrumb-bg.png";

  async function fetchProducts() {
    try {
      const response = await fetch(PRODUCT_PAST_AUCTIONS_LIST_URL);
      const json = await response.json();
      setProductData(json.data);
    } catch (err) {
      console.error("Error fetching products:", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <DashboardLayout>
      <>
        <div className="container live-auction-area">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-header-two">
                <h3>Expired Auction</h3>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {loading ? (
              <SkeletonCard />
            ) : productData.length > 0 ? (
              productData.map((item) => (
                <div className="col-lg-4 col-md-6" key={item._id}>
                  <div className="product-card product-beta-md">
                    <div className="product-header">
                      <h6 className="product-title">{item.productName}</h6>
                      <h4 className="price">{getPricingText(item)}</h4>
                    </div>
                    <div className="product">
                      <a href={`/products/${item._id}`} className="bid-btn">
                        <img
                          src={`${PRODUCT_IMAGE_URL}/${item.featuredImage}`}
                          alt={item.productName}
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                    </div>
                    <div className="product-disc">
                      <a href={`/products/${item._id}`} className="bid-btn">
                        {getButtonText(item)}
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12 error-wrapper-start">
                <div className="container">
                  <div className="col-lg-12">
                    <div className="error-content text-center">
                      <div className="error-texts mx-auto">
                        <h5 className="themeTextColor">
                          Keep an Eye Out for Our Next Round of Thrilling
                          Auctions!
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

const getPricingText = (item) => {
  const isBidExpired = +new Date() > +new Date(item.bidEndDate);

  if (item.sellingType === "both" || item.sellingType === "bidnow") {
    if (item.isOrdered) {
      return `Sold for $${item.orderDetails.orderAmount.toFixed(0)}`;
    } else if (isBidExpired && item.isBidWon) {
      return `Winning Bid: $${item.bidsDetails[0].bidAmount.toFixed(0)}`;
    } else if (!isBidExpired && item.bidsDetails.length === 0) {
      return `Starting Bid: $${item.lowestPriceAccept}`;
    } else if (isBidExpired) {
      return `Auction Expired`;
    } else {
      return `Current Bid: $${item.bidsDetails[0].bidAmount.toFixed(0)}`;
    }
  } else if (item.sellingType === "both" || item.sellingType === "buynow") {
    if (item.isOrdered) {
      return `Sold for $${item.buyNowPrice}`;
    } else {
      return `$${item.buyNowPrice}`;
    }
  }
};

const getButtonText = (item) => {
  const isBidExpired = +new Date() > +new Date(item.bidEndDate);

  if (item.sellingType === "both" || item.sellingType === "bidnow") {
    if (item.isOrdered) {
      return "Sold Out";
    } else if (item.isBidWon) {
      return "Bid Won"; // Or any appropriate text for the winning bid
    } else if (isBidExpired) {
      return "Expired";
    } else {
      return "Bid Now";
    }
  } else if (item.sellingType === "both" || item.sellingType === "buynow") {
    if (item.isOrdered) {
      return "Sold Out";
    } else if (isBidExpired) {
      return "Expired";
    } else {
      return "Buy it Now";
    }
  }
};

// const getPricingText = (item) => {
//   const isBidExpired = +new Date() > +new Date(item.bidEndDate);

//   if (((item.sellingType === "buynow" || item.sellingType === "both") && item.isOrdered)) {
//     return `Sold for $${item.orderDetails.orderAmount.toFixed(0)}`;
//   }

//   if (((item.sellingType === "bidnow" || item.sellingType === "both") && item.isBidWon)) {
//     return `Sold for $${item.bidsDetails[0].bidAmount.toFixed(0)}`;
//   }

//   // if ((item.isBidWon || item.isOrdered)) {
//   //   return `Expired`;
//   // }

//   if (!isBidExpired && item.sellingType === "buynow" && !item.isOrdered) {
//     return `$${item.buyNowPrice}`;
//   }

//   if (!isBidExpired && item.bidsDetails.length === 0) {
//     return `Starting Bid: $${item.lowestPriceAccept}`;
//   }

//   if (isBidExpired) {
//     return `Auction Expired`;
//   }

//   return `Current Bid: $${item.bidsDetails[0].bidAmount.toFixed(0)}`;
// };

// const getButtonText = (item) => {
//   const isBidExpired = +new Date() > +new Date(item.bidEndDate);

//   if (((item.sellingType === "buynow" || item.sellingType === "both") && item.isOrdered)) {
//     return "Sold Out";
//   }

//   if (isBidExpired && (item.isBidWon || item.isOrdered) || item.bidsDetails.length === 0 || item.orderDetails.length === 0) {
//     return "Expired";
//   }

//   if (!isBidExpired && item.sellingType === "buynow") {
//     return "Buy It Now";
//   }

//   if (!isBidExpired && item.bidsDetails.length === 0) {
//     return "Bid Now";
//   }

//   return "Bid Now";
// };

export default Home;
