import { GENERAL_SETTING_GET_URL } from "./Constant";
const moment = require('moment-timezone');

const fetchData = async (url, token) => {
  // const authToken = useSelector(state => state.token);
  //console.log("authToken", token.token)
  // const authToken = await loginUser();
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token.token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();

    return responseData; // Return the data
  } catch (error) {
    console.error('API Error:', error.message);
    throw error; // Re-throw the error
  }
};

const generalSettingData = async (token) => {
  return fetchData(GENERAL_SETTING_GET_URL, token)
}

function amountToWords(amount) {
    // Define arrays for one-digit and two-digit numbers in words
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  
    // Function to convert a number less than 1000 to words
    function convertLessThanThousand(number) {
      if (number === 0) {
        return '';
      } else if (number < 10) {
        return ones[number];
      } else if (number < 20) {
        return teens[number - 11];
      } else if (number < 100) {
        return `${tens[Math.floor(number / 10)]} ${convertLessThanThousand(number % 10)}`;
      } else {
        return `${ones[Math.floor(number / 100)]} Hundred ${convertLessThanThousand(number % 100)}`;
      }
    }
  
    // Function to convert a number to words
    function convertNumberToWords(number) {
      if (number === 0) {
        return 'Zero';
      } else {
        const billion = Math.floor(number / 1000000000);
        const million = Math.floor((number % 1000000000) / 1000000);
        const thousand = Math.floor((number % 1000000) / 1000);
        const remainder = number % 1000;
  
        let result = '';
  
        if (billion > 0) {
          result += `${convertLessThanThousand(billion)} Billion `;
        }
  
        if (million > 0) {
          result += `${convertLessThanThousand(million)} Million `;
        }
  
        if (thousand > 0) {
          result += `${convertLessThanThousand(thousand)} Thousand `;
        }
  
        if (remainder > 0) {
          result += convertLessThanThousand(remainder);
        }
  
        return result.trim();
      }
    }
  
  
  
  // Format the amount to have two decimal places
    const formattedAmount = Number(amount).toFixed(2);
     // Split the amount into dollars and cents
    const [dollars, cents] = formattedAmount.split('.');
   // Convert dollars and cents to words
    const dollarsInWords = convertNumberToWords(parseInt(dollars, 10));
    const centsInWords = cents !== '00' ? ` and ${convertNumberToWords(parseInt(cents, 10))} Cents` : '';
  
     // Construct the final result
    let result = dollarsInWords ? `${dollarsInWords} Dollars` : '';
    result += centsInWords;
  
    return result || 'Zero Dollars';
  
  
  }

  function convertIsoToCustomFormat(isoDate) {
    // const dateObject = moment.tz(isoDate, 'America/Los_Angeles');
    // const month = dateObject.format('MMMM');
    // const day = dateObject.format('DD');
    // const year = dateObject.format('YYYY');
    // const hours = dateObject.format('HH');
    // const minutes = dateObject.format('mm');
    // const period = dateObject.format('A');
  
    // const formattedDate = `${month} ${day}, ${year} at ${hours}:${minutes} ${period}`;
  
    // return formattedDate;
    // Use Moment.js to check if the UTC date is already converted to local time
  if (!moment(isoDate).isUTC()) {
    return moment(isoDate).format("MMMM DD, YYYY [at] h:mm A"); // Return the UTC date itself if it's already converted to local time
  }

  // If it's in UTC, convert it to local time
  return moment(isoDate).local().format("MMMM DD, YYYY [at] h:mm A");
}


  export { 
    // usersList, 
    // categoryList,
    // formatDate,
    generalSettingData,
    amountToWords,
    convertIsoToCustomFormat
  };