

import React, { useState, useEffect } from "react";

const CountdownTimer = ({ startDate, endDate }) => {
  const calculateTimeLeft = (targetDate) => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate));
  const isBidNotStarted = +new Date() < +new Date(startDate);
  const isBidStarted =!isBidNotStarted &&
    +new Date() >= +new Date(startDate) &&
    +new Date() <= +new Date(endDate);
  const isBidExpired = +new Date() > +new Date(endDate);

  useEffect(() => {
    const targetDate = isBidNotStarted ? startDate : endDate;
    
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [startDate, endDate, isBidNotStarted]);

  return (
    <h6>
    {isBidNotStarted && (
      <span>
        Auction Will Start in:
        <span>
          <br></br>
          <br></br>
          <span id="days4">{timeLeft.days}</span>D :
          <span id="hours4">{timeLeft.hours}</span>H :
          <span id="minutes4">{timeLeft.minutes}</span>M :
          <span id="seconds4">{timeLeft.seconds}</span>S
        </span>
      </span>
    )}
    {isBidStarted && (
      <span>
        Auction is Live and will expire on:
        <span>
          <br></br>
          <br></br>
          <span id="days4">{timeLeft.days}</span>D :
          <span id="hours4">{timeLeft.hours}</span>H :
          <span id="minutes4">{timeLeft.minutes}</span>M :
          <span id="seconds4">{timeLeft.seconds}</span>S
        </span>
      </span>
    )}
    {isBidExpired && <span>Auction Expired</span>}
  </h6>
  );
};


export default CountdownTimer;