import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/WebsiteLayout";
import { PRODUCT_DETAILS_URL, PRODUCT_IMAGE_URL } from "../../utils/Constant";
import ProductsOverview from "../../components/Products/ProductsOverview";
import ProductBidHistory from "../../components/Products/ProductBidHistory";

import CountdownTimer from "../../components/Products/CountdownTimer";
import ProductBidNow from "../../components/Products/ProductBidNow";
import { ProductMisSection } from "../../components/Products/ProductMisSection";

import NotFoundPage from "../NotFoundPage";

const ProductDetails = () => {
  const [productDetailsData, setProductDetailsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeImage, setActiveImage] = useState(0);
  const [responseStatus, setResponseStatus] = useState(0);
  const params = useParams();
  const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    try {
      const response = await fetch(`${PRODUCT_DETAILS_URL}/${params.id}`);
      setResponseStatus(response.status);
      if (response.status === 404) {
        // Product not found, navigate to NotFoundPage
        setLoading(false);
        navigate("/page-not-found");
        return; // Exit the function to prevent further execution
      } else {
        const jsonData = await response.json();
        if (jsonData.status === "success" && jsonData.data) {
          setProductDetailsData(jsonData.data);
        } else {
          console.error("API response indicates failure:", jsonData);
          navigate("/page-not-found");
        }
        setLoading(false);
      }
    } catch (err) {
      console.error("Error fetching product details:", err);
    }
  }, [params.id, navigate]);

  useEffect(() => {
    fetchProducts();
    // Refresh data every second
    const interval = setInterval(() => {
      //console.log("Fetching product details...");
      fetchProducts();
    }, 1000);

    return () => {
      clearInterval(interval); // Cleanup interval on component unmount
      //console.log("Interval cleared");
    };
  }, [params.id, fetchProducts]);

  const handleImageClick = (index) => {
    setActiveImage(index);
  };

  const imageGallery = productDetailsData?.imageGallery ?? [];
  const featuredImage = productDetailsData?.featuredImage ?? "";

  const newImageGallery = [
    featuredImage,
    ...imageGallery.map((item) => item.imageName),
  ];

  // callback function for getting updated without reloading

  const handleBidPlaced = async () => {
    // Update state or call fetch method here
    await fetchProducts();
  };

  return (
    <>
      {loading ? (
        <div className="preloader">
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      ) : responseStatus !== 404 ? (
        <DashboardLayout>
          <>
            <section className="auction-details-section pt-110">
              <div className="container">
                <div className="row gy-5">
                  <>
                    <div className="col-lg-12">
                      <div className="single-widget">
                        <h5 class="wdget-title">
                          {productDetailsData.productName}
                        </h5>

                        <p className="paragraph">
                          {productDetailsData.productShortDescription}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="item-bid-timer">
                        <div className="auction-bid">
                          {productDetailsData.isOrdered ? (
                            <>
                              <p>Sold for</p>
                              <h5>
                                ${productDetailsData?.orders[0]?.orderAmount}
                              </h5>
                            </>
                          ) : productDetailsData.isBidWon ? (
                            <>
                              <p>Winning Bid</p>
                              <h5>
                                ${productDetailsData?.allBids[0]?.bidAmount}
                              </h5>
                            </>
                          ) : (
                            <>
                              <p>Current Bid</p>
                              <h5>${productDetailsData.maxBid}</h5>
                            </>
                          )}
                        </div>
                        <div className="auction-timer">
                          <div className="countdown" id="timer4">
                            {productDetailsData.isOrdered ? (
                              <h5>Sold Out</h5>
                            ) : (
                              <CountdownTimer
                                startDate={productDetailsData.bidStartDate}
                                endDate={productDetailsData.bidEndDate}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="tab-content">
                        {newImageGallery?.map((image, index) => (
                          <div
                            key={index}
                            className={`tab-pane big-image fade ${
                              activeImage === index ? "show active" : ""
                            }`}
                            id={`gallery-img${index}`}
                          >
                            <img
                              src={`${PRODUCT_IMAGE_URL}/${image}`}
                              alt={`${index + 1}`}
                              style={{
                                height: "400px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      <ul className="nav small-image-list d-flex gap-md-4 gap-4">
                        {newImageGallery?.map((image, index) => (
                          <li className="nav-item" key={index}>
                            <div
                              onClick={() => handleImageClick(index)}
                              data-bs-toggle="pill"
                              data-bs-target={`#gallery-img${index}`}
                              aria-controls={`gallery-img${index}`}
                            >
                              <img
                                src={`${PRODUCT_IMAGE_URL}/${image}`}
                                alt={`${index + 1}`}
                                style={{
                                  width: "90px",
                                  height: "80px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="widget-right-area">
                        <ProductsOverview
                          productDetailsData={productDetailsData}
                        />

                        <ProductMisSection
                          productDetailsData={productDetailsData}
                        />

                        <ProductBidNow
                          // inputBid={inputBid}
                          // handleChangeBid={handleChangeBid}
                          onBidPlaced={handleBidPlaced}
                          // handleSuccess={handleSuccess}
                          productDetailsData={productDetailsData}
                        />
                        {(productDetailsData.sellingType === "both" ||
                          productDetailsData.sellingType === "bidnow") &&
                          !productDetailsData.isOrdered && (
                            <ProductBidHistory
                              allBids={productDetailsData.allBids}
                            />
                          )}
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </section>
          </>
        </DashboardLayout>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};
export default ProductDetails;
