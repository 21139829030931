import React, { useState,useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BIDS_CREATE_URL,USER_PROFILE_URL } from "../../utils/Constant";
import PaymentMethod from "../../pages/MyAccount/PaymentMethod";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";


const fetchUserData = async (authToken, setUserProfileData, setIsFeePaid) => {
  try {
    const response = await axios.get(USER_PROFILE_URL, {
      headers: {
        Authorization: `Bearer ${authToken.token}`,
        "Content-Type": "application/json",
      },
    });
    const data = response.data.data;
    setUserProfileData(data);
    setIsFeePaid(data.isFeePaid);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const ProductBidNow = ({ productDetailsData, onBidPlaced }) => {



  
  const isBidNotStarted = +new Date() < +new Date(productDetailsData.bidStartDate);
  const isBidStarted =!isBidNotStarted &&
    +new Date() >= +new Date(productDetailsData.bidStartDate) &&
    +new Date() <= +new Date(productDetailsData.bidEndDate);
  const isBidExpired = +new Date() > +new Date(productDetailsData.bidEndDate);
console.log("isBidExpired",isBidExpired)

  const [showCCModal, setShowCCModal] = useState(false);
  const [inputBid, setInputBid] = useState("");
  const [accountErrorMessage, setAccountErrorMessage] = useState("");
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] = useState("");
  const [isFeePaid, setIsFeePaid] = useState(false);
  const [userProfileData, setUserProfileData] = useState({});
  const [bidAmountErrorMessage, setBidAmountErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // For bid loading indicator
  const params = useParams();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.token);


  useEffect(() => {
    if (authToken) {
      fetchUserData(authToken, setUserProfileData, setIsFeePaid);
    }
  }, [authToken]);

console.log("userProfileData",userProfileData);
  const handleChangeBid = (event) => {
    setInputBid(event.target.value);
  };

  // Function to handle CC details submission and close modal
  const handleCCDetailsSubmit = () => {
    setShowCCModal(false);
  };
  //console.log("productDetailsData",productDetailsData)
  const minBidAmount =
    productDetailsData.maxBid > 0
      ? productDetailsData.maxBid + productDetailsData.bidIncrement
      : productDetailsData.lowestPriceAccept;

  const handleBidNow = async (e) => {
    e.preventDefault();
    setAccountErrorMessage(""); // Clear any previous account error messages
    setSubscriptionErrorMessage(""); // Clear any previous subscription error messages
    setBidAmountErrorMessage(""); // Clear any previous bid amount error messages
    setSuccessMessage(""); // Clear any previous success messages
    if (!authToken?.token || authToken?.token === "undefined") {
      setAccountErrorMessage("You should have an account to place a bid");
      return;
    }
    // if (!authToken?.isCCFilled) {
    //   setShowCCModal(true);
    //   return;
    // }

    if (authToken?.token && !isFeePaid) {
      setSubscriptionErrorMessage(
        "You should have subscribed first."
      );
      return;
    }
    
    if (+inputBid < minBidAmount) {
      setBidAmountErrorMessage(
        `Bid amount should be greater than $${minBidAmount}`
      );
      return;
    }

    

    setLoading(true); // Show loading indicator
    try {
      const bidData = {
        customerId: authToken.user_id,
        productId: params.id,
        bidAmount: inputBid,
      };
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken.token}`,
        },
        body: JSON.stringify(bidData),
      };
      const response = await fetch(BIDS_CREATE_URL, config);
      if (response.ok) {
        onBidPlaced();
        setInputBid(""); // Clear the bid amount input
        setSuccessMessage("Bid placed successfully!");
        setTimeout(function () {
          setSuccessMessage("");
        }, 3000);
      } else {
        const errorData = await response.json();
        setBidAmountErrorMessage(`Error: ${JSON.stringify(errorData)}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setBidAmountErrorMessage(
        "Error: Unable to place bid. Please try again later."
      );
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const openPaymentPopup = (e) => {
    e.preventDefault();
    setAccountErrorMessage(""); // Clear any previous account error messages
    setSubscriptionErrorMessage(""); // Clear any previous subscription error messages
    setSuccessMessage(""); // Clear any previous success messages
    if (!authToken?.token || authToken?.token === "undefined") {
      setAccountErrorMessage(
        "You should have an account to proceed with the purchase"
      );
      return;
    }

    if (authToken?.token && !isFeePaid) {
      setSubscriptionErrorMessage(
        "You should have subscribed first."
      );
      return;
    }


    if (!authToken?.isCCFilled) {
      setShowCCModal(true);
      return;
    }
    // If logged in and credit card details are filled, navigate to the payment page
    navigate(`/product/pay-now/${productDetailsData._id}`);
  };

  
  return (
    <>
      {productDetailsData.isOrdered ? (
        <div className="single-widget">
          <h5 style={{ color: "green", fontSize: "20px", fontWeight: "bold" }}>
            Sold for : ${productDetailsData?.orders[0]?.orderAmount}
          </h5>
        </div>
      ) : productDetailsData.isBidWon ? (
        <div className="single-widget">
          <h5 style={{ color: "green", fontSize: "20px", fontWeight: "bold" }}>
            Winning Bid : ${productDetailsData?.allBids[0]?.bidAmount}
          </h5>
        </div>
      ) : isBidExpired ? (
        <div className="single-widget">
          <span style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>
            Auction Expired because there were no bids
          </span>
        </div>
      ) : isBidNotStarted  ? (
        <div className="single-widget">
          <span style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>
            Auction will start soon..
          </span>
        </div>
      ) : (
        <>
          {productDetailsData.sellingType === "bidnow" && (
            <div className="single-widget auctionNboth">
              <h5 className="wdget-title">Custom Bid</h5>
              <p className="widget-subtitle">
                Bid Amount : Minimum Bid ${minBidAmount}
              </p>
              <div className="widget-form">
                <div style={{ display: "flex", gap: "60px" }}>
                  <div
                    className="form-group d-flex justify-content-center align-items-center"
                    style={{ width: "100%" }}
                  >
                    {loading ? (
                      // Show loader if bid is in progress
                      <div
                        className="spinner-border"
                        style={{
                          width: "2rem",
                          height: "2rem",
                          color: "#c21557",
                        }}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <>
                        <input
                          type="number"
                          placeholder="$00.00"
                          value={inputBid}
                          onChange={handleChangeBid}
                          required
                        />
                        <button className="widget-btn" onClick={handleBidNow}>
                          {loading ? "Placing Bid..." : "Place Bid"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
                {accountErrorMessage && (
                  <div className="error-message">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: accountErrorMessage.replace(/"/g, "'"),
                      }}
                    ></span>
                    <br />
                    <span>
                      <a style={{ "border-bottom": "2px solid" }} href="/login">
                        Login
                      </a>{" "}
                      or{" "}
                      <a
                        href="/register"
                        style={{ "border-bottom": "2px solid" }}
                      >
                        Register
                      </a>
                    </span>
                  </div>
                )}
                {bidAmountErrorMessage && (
                  <div className="error-message">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: bidAmountErrorMessage.replace(/"/g, "'"),
                      }}
                    ></span>
                  </div>
                )}

                {successMessage && (
                  <div className="success-message">{successMessage}</div>
                )}
              </div>
            </div>
          )}

          {productDetailsData.sellingType === "buynow" && (
            <div className="single-widget buyNboth">
              <h5 className="wdget-title">Buy Now</h5>
              <div className="widget-form">
                <div style={{ width: "100%", marginTop: "20px" }}>
                  <div className="form-group">
                    <button
                      className="widget-btn"
                      style={{
                        fontSize: "16px",
                        padding: "13px",
                        width: "100%",
                      }}
                      onClick={openPaymentPopup}
                    >
                      Buy Now ${productDetailsData.buyNowPrice}
                    </button>
                  </div>
                </div>
                {accountErrorMessage && (
                  <div className="error-message">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: accountErrorMessage.replace(/"/g, "'"),
                      }}
                    ></span>
                    <br />
                    <span>
                      <a style={{ "border-bottom": "2px solid" }} href="/login">
                        Login
                      </a>{" "}
                      or{" "}
                      <a
                        href="/register"
                        style={{ "border-bottom": "2px solid" }}
                      >
                        Register
                      </a>
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {productDetailsData.sellingType === "both" && (
            <>
              <div className="single-widget auctionNboth">
                <h5 className="wdget-title">Custom Bid</h5>
                <p className="widget-subtitle">
                  Bid Amount : Minimum Bid ${minBidAmount}
                </p>
                <div className="widget-form">
                  <div style={{ display: "flex", gap: "60px" }}>
                    <div
                      className="form-group d-flex justify-content-center align-items-center"
                      style={{ width: "100%" }}
                    >
                      {loading ? (
                        // Show loader if bid is in progress
                        <div
                          className="spinner-border"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            color: "#c21557",
                          }}
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <input
                            type="number"
                            placeholder="$00.00"
                            value={inputBid}
                            onChange={handleChangeBid}
                            required
                          />
                          <button className="widget-btn" onClick={handleBidNow}>
                            {loading ? "Placing Bid..." : "Place Bid"}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  {accountErrorMessage && (
                    <div className="error-message">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: accountErrorMessage.replace(/"/g, "'"),
                        }}
                      ></span>
                      <br />
                      <span>
                        <a
                          style={{ "border-bottom": "2px solid" }}
                          href="/login"
                        >
                          Login
                        </a>{" "}
                        or{" "}
                        <a
                          href="/register"
                          style={{ "border-bottom": "2px solid" }}
                        >
                          Register
                        </a>
                      </span>
                    </div>
                  )}
                  {bidAmountErrorMessage && (
                    <div className="error-message">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: bidAmountErrorMessage.replace(/"/g, "'"),
                        }}
                      ></span>
                    </div>
                  )}

{subscriptionErrorMessage && (
                  <div className="error-message">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: subscriptionErrorMessage.replace(/"/g, "'"),
                      }}
                    ></span>
                    <br />
                    <span>
                      <a style={{ "border-bottom": "2px solid" }} href="/my-account">
                        Subscribe Now
                      </a>{" "}
                     
                    </span>
                  </div>
                )}

                  {successMessage && (
                    <div className="success-message">{successMessage}</div>
                  )}

                  <div style={{ width: "100%", marginTop: "20px" }}>
                    <div className="form-group">
                      <button
                        className="widget-btn"
                        style={{
                          fontSize: "16px",
                          padding: "13px",
                          width: "100%",
                        }}
                        onClick={openPaymentPopup}
                      >
                        Buy Now ${productDetailsData.buyNowPrice}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <Modal show={showCCModal} onHide={() => setShowCCModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Credit Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentMethod onCCDetailsSubmit={handleCCDetailsSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCCModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductBidNow;
