import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import DashboardLayout from "../../layouts/WebsiteLayout";
import MyOrdersComponent from "./MyOrdersComponent";
import MyBidsComponent from "./MyBidsComponent";
import DashboardKPIsComponent from "./dashboardKPIsComponent";
import ChangePassword from "./ChangePassword";
import PaymentMethod from "./PaymentMethod";
import AccountDetails from "./AccountDetails";
import PayPalButton from "../Paypal/Paypal";
import { clearToken } from "../../utils/reducers/authSlice";
import "./MyAccount.css"; // Assuming styles are consolidated here

import {
  USER_UPDATE_PAYMENT_URL,
  USER_PROFILE_URL,
} from "../../utils/Constant";

const fetchUserData = async (authToken, setUserProfileData, setIsFeePaid) => {
  try {
    const response = await axios.get(USER_PROFILE_URL, {
      headers: {
        Authorization: `Bearer ${authToken.token}`,
        "Content-Type": "application/json",
      },
    });
    const data = response.data.data;
    setUserProfileData(data);
    setIsFeePaid(data.isFeePaid);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const MyAccount = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [modalVisible, setModalVisible] = useState(false);
  const [isFeePaid, setIsFeePaid] = useState(false);
  const [paypalResponse, setPaypalResponse] = useState(null);
  const [message, setMessage] = useState("");
  const [userProfileData, setUserProfileData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.token);

  useEffect(() => {
    if (authToken) {
      fetchUserData(authToken, setUserProfileData, setIsFeePaid);
    }
  }, [authToken, paypalResponse]);

  useEffect(() => {
    if (!authToken) {
      navigate("/login");
    }
  }, [authToken, navigate]);

  useEffect(() => {
    const tab = location.hash.substring(1);
    setActiveTab(tab || "dashboard");
  }, [location.hash]);

  const handleLogout = () => {
    dispatch(clearToken());
    navigate("/login");
  };

  const handleSuccess = async (details) => {
    setPaypalResponse(details);

    try {
      const response = await axios.post(
        USER_UPDATE_PAYMENT_URL,
        {
          feePaidAmount: 5,
          paymentStatus: details.status,
          transactionId: details.id,
          paymentTime: details.update_time,
          paypalResponse: details,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken.token}`,
          },
        }
      );

      setMessage(
        response.data.message ||
          "Failed to send payment details to the database."
      );
    } catch (error) {
      console.error("Error sending payment details to the database:", error);
      setMessage("Error sending payment details to the database.");
    }

    // Close the modal after 2 seconds
    setTimeout(() => {
      resetModal();
    }, 5000);
  };

  const resetModal = () => {
    setModalVisible(false);
    setPaypalResponse(null);
    setMessage("");
  };

  return (
    <DashboardLayout>
      <div className="my-account-page container">
        <div className="row">
          <div className="col-12">
            <div className="account-dashboard">
              {!isFeePaid ? (
                <div className="subscription-section row">
                  <div className="sub-message col-sm-9">
                    <p className="subscriptionText"> You need to subscribe with a one-time fee to buy and bid on any product. Once subscribed, it will work forever. </p>
                  </div>

                  <div className="sub-button col-sm-3">
                    <button
                      className="btn default-btn"
                      onClick={() => setModalVisible(true)}
                    >
                      Subscribe Now
                    </button>
                  </div>
                </div>
              ) : (
                <div className="subscription-section row">
                  <div className="sub-message col-sm-12">
                    <p className="subscriptionText">
                      You are a subscribed user. <br />Enjoy buying and bidding on any
                      product! Expiry Forever !
                    </p>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12 col-lg-2">
                  <ul className="nav flex-column dashboard-list">
                    <li className="nav-item">
                      <a
                        href="#dashboard"
                        className={`nav-link ${
                          activeTab === "dashboard" ? "active" : ""
                        }`}
                      >
                        Dashboard
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#orders"
                        className={`nav-link ${
                          activeTab === "orders" ? "active" : ""
                        }`}
                      >
                        My Orders
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#bids"
                        className={`nav-link ${
                          activeTab === "bids" ? "active" : ""
                        }`}
                      >
                        My Bids
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        href="#paymentMethod"
                        className={`nav-link ${
                          activeTab === "paymentMethod" ? "active" : ""
                        }`}
                      >
                        Payment Method
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a
                        href="#account-details"
                        className={`nav-link ${
                          activeTab === "account-details" ? "active" : ""
                        }`}
                      >
                        Account Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#change-password"
                        className={`nav-link ${
                          activeTab === "change-password" ? "active" : ""
                        }`}
                      >
                        Change Password
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/" onClick={handleLogout} className="nav-link">
                        {authToken ? <span>Sign Out</span> : <span>Login</span>}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 col-lg-10 container">
                  <div className="tab-content dashboard-content">
                    <div
                      className={`tab-pane ${
                        activeTab === "dashboard" ? "active show" : "fade"
                      }`}
                      id="dashboard"
                    >
                      {/* <DashboardKPIsComponent /> */}
                      <p>
                        <b>
                          Hello, {authToken?.username} (not{" "}
                          {authToken?.username}?
                          <Link to="/" onClick={handleLogout}>
                            &nbsp;Sign Out
                          </Link>
                          )
                        </b>
                        <br />
                        <br />
                        Welcome to your personalized dashboard. Here, you can
                        manage and monitor various aspects of your account.
                      </p>
                      <br />
                      <p>
                        From your account dashboard, you can easily check and
                        view your <a href="#orders">recent orders</a>,{" "}
                        <a href="#bids">Bids</a>, and{" "}
                        <a href="#change-password">edit your password</a> and{" "}
                        <a href="#account-details">account details</a>.
                      </p>
                      <br />
                      <p>
                        If you have any questions or need assistance, feel free
                        to <a href="/contact">Contact Us</a>. We're here to
                        help!
                      </p>
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "orders" ? "active show" : "fade"
                      }`}
                      id="orders"
                    >
                      <MyOrdersComponent />
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "bids" ? "active show" : "fade"
                      }`}
                      id="bids"
                    >
                      <MyBidsComponent />
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "paymentMethod" ? "active show" : "fade"
                      }`}
                      id="paymentMethod"
                    >
                      <PaymentMethod />
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "account-details" ? "active show" : "fade"
                      }`}
                      id="account-details"
                    >
                      <AccountDetails />
                    </div>
                    <div
                      className={`tab-pane ${
                        activeTab === "change-password" ? "active show" : "fade"
                      }`}
                      id="change-password"
                    >
                      <ChangePassword />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Buy Now Modal */}
        <div
          className={`modal ${modalVisible ? "show d-block" : "d-none"}`}
          tabIndex="-1"
          role="dialog"
          style={{ background: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Buy Subscription</h5>
                <button
                  type="button"
                  className="close"
                  onClick={resetModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {!paypalResponse ? (
                  <PayPalButton totalAmount="5" onSuccess={handleSuccess} />
                ) : (
                  <div className="payment-success-container">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/dist/img/check-green.gif"
                      }
                      alt="logo"
                    />
                    <h4>{message}</h4>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={resetModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {modalVisible && <div className="modal-backdrop show"></div>}
      </div>
    </DashboardLayout>
  );
};

export default MyAccount;
