import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../../utils/reducers/authSlice";
import { USER_IMAGE_URL } from "../../utils/Constant";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [isDropdownOpenTop, setDropdownOpenTop] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const authToken = useSelector((state) => state.token);
  let isLoggedIn = authToken;
  let loggedInUsername = authToken?.username;
  let loggedInImage = authToken?.image;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    // Do nothing if the menu items are being interacted with
    if (!isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const handleLogout = () => {
    dispatch(clearToken());
    //navigate("/login");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  const toggleDropdownTop = () => {
    setDropdownOpenTop(!isDropdownOpenTop);
  };

  return (
    <>
      <div className="topbar-area topbar-style-one py-2 py-md-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-md-4 col-sm-6 col">
              <div className="topbar-mail">
                <i className="bi bi-envelope" />
                <a href="mailto:info@treasuretrad.com">
                  <span>info@treasuretrad.com</span>
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 d-none d-md-block">
              <p class="slogan">A place to Buy or Sell neighborhood treasure</p>
              {/* <ul className="topbar-social-icons d-flex justify-content-center">
                <li>
                  <a href="https://www.facebook.com/">
                    <i className="bx bxl-facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/">
                    <i className="bx bxl-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.pinterest.com/">
                    <i className="bx bxl-pinterest" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/">
                    <i className="bx bxl-instagram" />
                  </a>
                </li>
              </ul> */}
            </div>
            <div className="col-xl-4 col-md-4 d-none d-md-block">
              <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav ml-auto">
                    {/* <li>
                      <span className="nav-link">{loggedInUsername ? "Welcome, "+loggedInUsername+"!" : ""}</span>
                    </li>
                    {isLoggedIn ?(
                    <li class="nav-item">
                      
                      <a href="/my-account" className="nav-link">
                          <i className="fas fa-user mr-2"></i>My Account
                        </a>
                    </li>) : ""} */}
                    {/* <li class="nav-item">
                      <a
                      href="/login"
                      className="nav-link"
                      onClick={handleLogout}
                    >
                      {isLoggedIn ? "Sign Out" : "Sign In"}
                    </a>
                    </li> */}

                    {isLoggedIn ? (
                      <li class="nav-item">
                        <div
                          className="account-menu"
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="welcome" onClick={toggleMenu}>
                            <a>
                              {loggedInUsername
                                ? "Hello, " + loggedInUsername + "!"
                                : ""}
                            </a>
                            &nbsp;
                            {loggedInImage ? (
                              <img
                                src={`${USER_IMAGE_URL}/${loggedInImage}`}
                                alt=""
                              />
                            ) : (
                              <i className={`fa fa-user`}></i>
                            )}
                            &nbsp;
                            <i
                              className={`fa ${
                                isMenuOpen ? "fa-caret-up" : "fa-caret-down"
                              }`}
                              onClick={toggleMenu}
                            ></i>
                          </div>
                          {isMenuOpen && (
                            <div
                              className="menu-items"
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              <ul>
                                <li>
                                  <a href="/my-account">My Account</a>
                                </li>
                                <li>
                                  <a href="/login" onClick={handleLogout}>
                                    Sign Out
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                    ) : (
                      <li>
                        <a href="/login" className="nav-link">
                          Sign In
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
              {/* <div className="navbar">
                <div
                  className={`user-icon ${isDropdownOpen ? "active" : ""}`}
                  onClick={toggleDropdown}
                  style={{ color: "white", fontSize: "14px" }}
                >
                  <i className="bx bxs-user"></i>
                </div>
                {isDropdownOpen && (
                  <div className="dropdown">
                    <span className="dropdown-header">
                      Hello {loggedInUsername ? loggedInUsername : ""}
                    </span>

                    {loggedInUsername ? (
                      <>
                        <div className="dropdown-divider"></div>
                        <a href="/my-account" className="dropdown-item">
                          <i className="fas fa-user mr-2"></i>My Account
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="dropdown-divider"></div>
                    <a
                      href="/login"
                      className="dropdown-item dropdown-footer"
                      onClick={handleLogout}
                    >
                      {isLoggedIn ? <p>Sign Out</p> : <p>Login</p>}
                    </a>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <header>
        <div className="header-area header-style-two ">
          <div className="container">
            <div className="row">
              <div className="col-xl-2 align-items-center d-xl-flex d-lg-block">
                <div className="nav-logo d-flex justify-content-between align-items-center">
                  <a href="/">
                    <img
                      style={{ "height": "60px","max-width": "80%","border-radius": "62px"}}
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/dist/img/cropped-mototrad.png"
                      }
                      alt="logo"
                    />
                  </a>
                  <div className="d-flex align-items-center gap-4">
                    <ul className="nav-actions nav-inner-actions d-flex d-xl-none">
                      {!loggedInUsername ? (
                        <li className="header-account me-0 position-relative signInContainer">
                          <a href="/login" className="nav-link signIn">
                            Sign In
                          </a>
                        </li>
                      ) : (
                        <li className="header-account me-0 position-relative accountContainer">
                          <Link
                            to="#"
                            id="dropdownMenu3"
                            data-bs-toggle="dropdown"
                            onClick={toggleDropdownTop}
                            className={`${isDropdownOpenTop ? "show" : ""}`}
                          >
                            {loggedInImage ? (
                              <>
                                <img
                                  src={`${USER_IMAGE_URL}/${loggedInImage}`}
                                  alt=""
                                  style={{ height: "35px", maxWidth: "100%" }}
                                />
                                <i className={`fas fa-caret-down`}></i>
                              </>
                            ) : (
                              <i className="bi bi-person-fill" />
                            )}
                          </Link>

                          <ul
                            className={`dropdown-menu account-list-mobile ${
                              isDropdownOpenTop ? "show" : ""
                            }`}
                            aria-labelledby="dropdownMenu3"
                            style={
                              isDropdownOpenTop
                                ? {
                                    position: "absolute",
                                    inset: "0px auto auto 0px",
                                    margin: "0px",
                                    transform: "translate(-174px, 25px)",
                                  }
                                : {}
                            }
                          >
                            {loggedInUsername ? (
                              <>
                                <li className="dropdown-item">
                                  <span>
                                    <Link>Hello, {loggedInUsername}</Link>
                                  </span>
                                </li>
                                <li className="dropdown-item">
                                  <a href="/my-account">My Account</a>
                                </li>
                              </>
                            ) : (
                              ""
                            )}
                            <li className="dropdown-item">
                              <Link to="/login" onClick={handleLogout}>
                                {isLoggedIn ? <>Sign Out</> : <>Login</>}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </ul>
                    <div className="mobile-menu d-flex">
                      <Link
                        to="#"
                        className={`hamburger d-block d-xl-none ${
                          isNavOpen ? "h-active" : ""
                        } `}
                        onClick={toggleNav}
                      >
                        <span className="h-top" />
                        <span className="h-middle" />
                        <span className="h-bottom" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-10">
                <nav
                  className={`main-nav text-xl-right text-start ${
                    isNavOpen ? "slidenav" : ""
                  } `}
                >
                  <div class="inner-logo d-xl-none mt-4 text-center">
                    <a href="#">
                      <img
                        style={{ "height": "60px","max-width": "60px","border-radius": "62px" }}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/dist/img/cropped-mototrad.png"
                        }
                        alt="logo"
                      />
                    </a>
                  </div>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/how-to-bid">How To Bid</a>
                    </li>
                    <li>
                      <a href="/expired-auctions">Past Auctions</a>
                    </li>
                    <li>
                      <a href="/philosophy">Philosophy</a>
                    </li>

                    {/* <li>
                      <a href="/faq">faq</a>
                    </li> */}
                    {/* <li>
                      <a href="/privacy">Privacy Policy</a>
                    </li> */}
                    {/* <li>
                      <a href="/contact-us">Sell Your Item</a>
                    </li> */}
                    {/* {isLoggedIn ? (
                      <li className="mobile-login-menu">
                        <Link to="/login" onClick={handleLogout}>
                          Sign Out
                        </Link>
                      </li>
                    ) : null} */}
                    {/* <li>
                      <a href="/contact">contact</a>
                    </li> */}
                  </ul>
                </nav>
              </div>

              {/* <div className="col-xl-3 d-none d-xl-flex align-items-center justify-content-end">
                <div className="nav-right d-flex align-items-center">
                
                  <div className="contact-no d-flex align-items-center">
                    <div className="contact-icon">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/dist/img/icon/phone.svg"
                        }
                        alt=""
                      />
                    </div>
                    <div className="contact-info">
                      <p>Hot Line Number</p>
                      <h6>
                        <a href="tel:+8801761111456">+880 176 1111 456</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
