import React from 'react'
import DashboardLayout from '../../layouts/WebsiteLayout'

const Privacy = () => {
    const imagePath = process.env.PUBLIC_URL + "/assets/dist/img/about-breadcrumb-bg.png";

    return (
        <DashboardLayout>
            <div className="breadcrumb breadcrumb-style-one mb-0" style={{ backgroundImage: `linear-gradient(rgba(0, 1, 13, 0.8), rgba(0, 1, 13, 0.8)), url(${imagePath})` }}>
                <div className="container">
                    <div className="col-lg-12 text-center">
                        <h1 className="breadcrumb-title">Privacy Policy</h1>
                        <ul className="d-flex justify-content-center breadcrumb-items">
                            <li className="breadcrumb-item">
                                <i className="bi bi-house-door" /> <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item active">Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="privacy-policy-wrapper mt-110">
                <div className="container">
                    <div className="row">
                        <div className="tab-content pp-tab-contents" id="v-pills-tabContent">
                        <blockquote class="wp-block-quote custom-block" ><p><strong><em>The sale information is NOT guaranteed to be correct. Bidders must use their judgment when purchasing items as all sales are final. The buyer is responsible for all registration fees And or smog certification. Bid responsibly!</em></strong></p></blockquote>
                            <div
                                className="tab-pane fade show active pp-tab-body"
                                id="v-pills-pp1"
                                role="tabpanel"
                                aria-labelledby="v-btn-pp1"
                            >
                                <div className="tab-inner-contentbox mt-0" >
                                    
                                    <p style={{ "fontSize": "18px" }}>Contact Treasuretrad for questions or concerns @ 7604014871 partzcheap@gmail.com </p><br />

                                    <p style={{ "fontSize": "18px" }}>Vehicles must be paid and removed within 48 hours after the sale.
                                        Any late payment or removal of item will be charged at $30 per day.</p><br />

                                    <p style={{ "fontSize": "18px" }}>Failure to remove the vehicle from the property after 1 week of the
                                        conclusion of the auction will result in the item being resold. Buyer fees will not be refunded.</p><br />

                                    <p style={{ "fontSize": "18px" }}>We are not responsible for loss or damage to vehicles that are not
                                        immediately paid for and removed from the property.</p><br />

                                    <p style={{ "fontSize": "18px" }}>All items are sold “AS IS WITH ALL FAULTS”.
                                        We hereby disclaim and exclude any and all implied warranties, including without limitation
                                        the implied warranties of merchantability and fitness for a particular purpose.</p><br />

                                    <p style={{ "fontSize": "18px" }}>By continuing I understand and agree to all of the terms and conditions stated above.</p><br />

                                    <p style={{ "fontSize": "18px" }}>Notwithstanding the above Terms and Conditions in any exception; the sole and exclusive
                                        remedy for breach of contract of warranty or any other reason, shall be return of the vehicle
                                        and repayment of the purchase price. Under no circumstance shall we be liable for consequential
                                        or incidental damage; including but not limited to repairs, improvements, loss of use, and DMV/Registration fees.</p><br />

                                    <p style={{ "fontSize": "18px" }}>Often, our clients have no way to determine how the vehicle drives. If they do get a vehicle started,
                                        they do not guarantee it will start again. They have been sitting in storage for months and all we
                                        are able to do is try to start it. Any defects noted are not intended to be exhaustive and are limited
                                        to observations at the moment. If you buy it and it does not start, there will not be a refund.
                                        Bid accordingly. If you have any questions regarding this notice please email/text in advance of bidding.</p><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Privacy