// MyOrdersComponent.jsx

import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ORDER_DETAIL_URL,
  ORDER_LIST_URL,
  PRODUCT_IMAGE_URL,
} from "../../utils/Constant";
import { amountToWords, convertIsoToCustomFormat } from "../../utils/Helper";

const MyOrdersComponent = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const authToken = useSelector((state) => state.token);
  const fetchData = async () => {
    try {
      const response = await fetch(ORDER_LIST_URL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken.token}`, // Replace with your actual token
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setOrdersData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to determine Bootstrap text color class based on payment status
  const getPaymentStatusColor = (paymentStatus) => {
    if (paymentStatus === "completed") {
      return "text-success";
    } else if (paymentStatus === "pending") {
      return "text-primary";
    } else {
      return "text-danger";
    }
  };

  // Function to determine Bootstrap text color class based on order status
  const getOrderStatusColor = (orderStatus) => {
    if (orderStatus === "processing") {
      return "text-warning";
    } else if (orderStatus === "shipped") {
      return "text-primary";
    } else if (orderStatus === "delivered") {
      return "text-success";
    } else if (orderStatus === "declined") {
      return "text-danger";
    } else {
      return "text-secondary";
    }
  };

  const renderTooltip = (id) => <Tooltip id="id-tooltip">{id}</Tooltip>;

  
  //console.log(ordersData);

  const fetchOrdersData = async (orderId) => {
    setLoading(true);
    try {
      const response = await fetch(`${ORDER_DETAIL_URL}/${orderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken.token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setOrderDetails(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleDetails = (orderId) => {
    //console.log(orderId);
    fetchOrdersData(orderId);
  };
  const totatBillAmount = orderDetails?.totalAmount;

  //console.log("orderDetails", ordersData);

  return (
    <>
      <h3>My Orders</h3>
      <div className="table-responsive">
        {loading ? (
          <div className="col-lg-12 col-sm-12 main-heading" style={{textAlign:"center"}}>
          <div
            className="spinner-border"
            style={{
              width: "4rem",
              height: "4rem",
              color: "#c21557",
              textAlign: "center",
            }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Order Amount</th>
                <th>Payment Status</th>
                <th>Order Time</th>
                <th>Payment Status</th>
                {/* <th>Transaction ID</th> */}
                <th>Order Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {ordersData.length == 0 ? (
                <tr>
                  <td colspan="8">
                    <center>No Orders found..</center>
                  </td>
                </tr>
              ) : (
                ordersData?.map((order) => (
                  <tr key={order._id}>
                    <td>
                      <span>{order?.orderId}</span>
                    </td>
                    <td>
                      <a href={`products/${order?.productId?._id}`}>
                        <b>{order?.productId?.productName}</b>
                      </a>
                    </td>
                    <td>${order.orderAmount}</td>
                    <td className="pendingAmount">
                      {order?.pendingAmount == 0 ? (
                        "Fully Paid"
                      ) : (
                        <>
                          <span> {`Paid ($${order?.paidAmount})`}</span>
                          <br />
                          <span class="red"> {`Pending ($${order?.pendingAmount})`}</span>
                          <br />
                        </>
                      )}
                    </td>
                    <td>
                      {convertIsoToCustomFormat(order?.orderTime)}
                      {/* {new Date(order?.orderTime).toISOString().split("T")[0]} */}
                    </td>
                    <td>
                      <span
                        className={`badge ${getPaymentStatusColor(
                          order.paymentStatus
                        )}`}
                      >
                        {order.paymentStatus}
                      </span>
                    </td>
                    {/* <td>
                    
                      <span>{order.transactionId}</span>
                    
                  </td> */}
                    <td>
                      <span
                        className={`badge ${getOrderStatusColor(
                          order.orderStatus
                        )}`}
                      >
                        {order.orderStatus}
                      </span>
                    </td>
                    <td>
                      <i
                        class="fa fa-eye"
                        className="btn btn-danger"
                        data-toggle="modal"
                        data-target="#exampleModalCenters"
                        onClick={() => handleDetails(order._id)}
                      >
                         <i class="bi bi-eye-fill"></i>
                      </i>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
      <div
        class="modal fade"
        id="exampleModalCenters"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                <b>
                  {orderDetails?.productId?.productName} (Order Id #
                  {orderDetails?.orderId})
                </b>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-sm-5">
                  <img
                    src={
                      PRODUCT_IMAGE_URL +
                      "/" +
                      orderDetails?.productId?.featuredImage
                    }
                    style={{
                      width: "100%",
                      height: "200px",
                      display: "block" /* Ensures image aligns in the center */,
                      margin: "auto" /* Aligns image in the center */,
                      borderRadius: "10px" /* Adds curved border */,
                    }}
                  />
                  <br />
                  <p>
                    <strong>Product Description:</strong>{" "}
                    {orderDetails?.productId?.productDescription}
                  </p>
                </div>
                <div className="col-sm-7">
                  <table className="modal-view table table-bordered orderDetailsModal ">
                    <tbody>
                      <tr>
                        <th scope="row" colspan="2" className="paymentDetails">
                          Product Details
                        </th>
                      </tr>
                      <tr>
                        <th scope="row">Product Name</th>
                        <td>{orderDetails?.productId?.productName}</td>
                      </tr>

                      <tr>
                        <th scope="row">Customer Name</th>
                        <td>
                          {orderDetails?.customerId?.firstName}{" "}
                          {orderDetails?.customerId?.lastName}
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Customer Address</th>
                        <td>{orderDetails?.customerId?.address}</td>
                      </tr>

                      <tr>
                        <th scope="row">Order Status</th>
                        <td>
                          <span
                            className={`badge ${getOrderStatusColor(
                              orderDetails.orderStatus
                            )}`}
                          >
                            {orderDetails.orderStatus}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Payment Status</th>
                        <td>
                          <span
                            className={`badge ${getPaymentStatusColor(
                              orderDetails.paymentStatus
                            )}`}
                          >
                            {orderDetails.paymentStatus}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Order Date Time</th>
                        <td>
                          {convertIsoToCustomFormat(orderDetails?.orderTime)}
                          {/* {new Date(orderDetails?.orderTime).toLocaleString()} */}
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" colspan="2" className="paymentDetails">
                          Payment Details
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: "50%" }}>Subtotal:</th>
                        <td>${orderDetails?.productId?.buyNowPrice}</td>
                      </tr>
                      <tr>
                        <th>
                          Treasuretrad Commission 
                          {/* (
                          {orderDetails?.premiumType === "flat"
                            ? "Flat"
                            : orderDetails?.premiumAmount + "%"}
                          ) */}
                        </th>
                        <td>${orderDetails?.premiumAmount}</td>
                      </tr>

                      <tr>
                        <th>
                          <h6>Total:</h6>
                        </th>
                        <td>
                          <h6>${totatBillAmount} </h6>(
                          {amountToWords(totatBillAmount)})
                        </td>
                      </tr>

                      {orderDetails?.orderType === "partial" ? (
                        <>
                          <tr  className="pendingAmount">
                            <th>
                              <span className="green">Paid Amount:</span>
                            </th>
                            <td>
                              <span className="green">${orderDetails?.paidAmount}</span>
                            </td>
                          </tr>
                          <tr className="pendingAmount">
                            <th>
                              <h6 className="red">Balance Amount:</h6>
                            </th>
                            <td>
                              <h6 className="red">
                                ${orderDetails?.pendingAmount}
                              </h6>
                              <span className="red">
                                ({amountToWords(orderDetails?.pendingAmount)})
                              </span>
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr className="pendingAmount">
                            <th>
                              <h6 className="green">Paid Amount:</h6>
                            </th>
                            <td>
                              <h6 className="green">${orderDetails?.paidAmount}</h6>
                              <span className="green">
                                ({amountToWords(orderDetails?.paidAmount)})
                              </span>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrdersComponent;
