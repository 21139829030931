import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  APP_URL,
  BIDS_DETAIL_URL,
  BIDS_LIST_URL,
  PRODUCT_IMAGE_URL,
} from "../../utils/Constant";
import { amountToWords, convertIsoToCustomFormat } from "../../utils/Helper";

const MyBidsComponent = () => {
  const [bidsData, setBidsData] = useState([]);
  const [bidDetails, setBidDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const authToken = useSelector((state) => state.token);

  const fetchData = async () => {
    try {
      const response = await fetch(BIDS_LIST_URL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken.token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setBidsData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const renderBidIdTooltip = (bidId) => (
  //   <Tooltip id={`bid-id-tooltip-${bidId}`}>{bidId}</Tooltip>
  // );


  // Function to determine Bootstrap text color class based on payment status
  const getPaymentStatusColor = (paymentStatus) => {
    if (paymentStatus === "completed") {
      return "text-success";
    } else if (paymentStatus === "pending") {
      return "text-primary";
    } else {
      return "text-danger";
    }
  };

  // Function to determine Bootstrap text color class based on order status
  const getOrderStatusColor = (orderStatus) => {
    if (orderStatus === "processing") {
      return "text-warning";
    } else if (orderStatus === "shipped") {
      return "text-primary";
    } else if (orderStatus === "delivered") {
      return "text-success";
    } else if (orderStatus === "declined") {
      return "text-danger";
    } else {
      return "text-secondary";
    }
  };

  // const renderTooltip = (id) => <Tooltip id="id-tooltip">{id}</Tooltip>;

  //console.log("bidsData", bidsData);

  const fetchBidsData = async (bidId) => {
    setLoading(true);
    try {
      const response = await fetch(`${BIDS_DETAIL_URL}/${bidId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken.token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setBidDetails(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleDetails = (bidId) => {
    //console.log(bidId);
    fetchBidsData(bidId);
  };
  //console.log("first", bidDetails);
  const totatBillAmount = bidDetails?.totalAmount;
  return (
    <>
      <h3>My Bids</h3>
      <div className="table-responsive">
        {loading ? (
          <div className="col-lg-12 col-sm-12 main-heading" style={{textAlign:"center"}}>
          <div
            className="spinner-border"
            style={{
              width: "4rem",
              height: "4rem",
              color: "#c21557",
              textAlign: "center",
            }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Bid Amount</th>
                <th>Bid Time</th>
                <th>Is Winner</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {bidsData.length == 0 ? (
                <tr>
                  <td colspan="6">
                    <center>No Bids found..</center>
                  </td>
                </tr>
              ) : (
                bidsData.map((bid) => (
                  <tr key={bid._id}>
                    <td>
                      <span>{bid.bidId}</span>
                    </td>
                    <td>
                      <a target="_blank" href={`products/${bid?.productId?._id}`}>
                        {bid?.productId?.productName}
                      </a>
                    </td>
                    <td>${bid?.bidAmount}</td>
                    <td>
                      {convertIsoToCustomFormat(bid?.bidTime)}
                      {/* {new Date(bid?.bidTime).toLocaleString()} */}
                    </td>
                    <td>{bid?.isWinner ? "Yes" : "No"}</td>
                    <td>
                      <i
                        class="fa fa-eye"
                        className="btn btn-danger"
                        data-toggle="modal"
                        data-target="#exampleModalCenterBid"
                        onClick={() => handleDetails(bid._id)}
                      >
                        
                        <i class="bi bi-eye-fill"></i>
                      </i>
                    </td>
                  </tr>
                  //
                ))
              )}
            </tbody>
          </table>
        )}
      </div>
      <div
        class="modal fade"
        id="exampleModalCenterBid"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterBidTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
              <b>{bidDetails?.productId?.productName}( Bid Id #{bidDetails?.bidId})</b>
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-sm-5">
                  <img
                    src={
                      PRODUCT_IMAGE_URL +
                      "/" +
                      bidDetails?.productId?.featuredImage
                    }
                    style={{
                      width: "100%",
                      height: "200px",
                      display: "block" /* Ensures image aligns in the center */,
                      margin: "auto" /* Aligns image in the center */,
                      borderRadius: "10px" /* Adds curved border */,
                    }}
                  />

<br />
              <p>
                <strong>Product Description:</strong>{" "}
                {bidDetails?.productId?.productDescription}
              </p>
                </div>
                {/* <div className="col-sm-7">
                  <table className="modal-view table table-bordered">
                    <tbody>
                      <tr>
                        <th scope="row">Product Name</th>
                        <td>{bidDetails?.productId?.productName}</td>
                      </tr>
                      <tr>
                        <th scope="row">Product Condition</th>
                        <td>{bidDetails?.productId?.productCondition}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Name</th>
                        <td>
                          {bidDetails?.customerId?.firstName}{" "}
                          {bidDetails?.customerId?.lastName}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Phone</th>
                        <td>{bidDetails?.customerId?.phone}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Address</th>
                        <td>{bidDetails?.customerId?.address}</td>
                      </tr>
                      <tr>
                        <th scope="row">Bid Amount</th>
                        <td>${bidDetails?.bidAmount}</td>
                      </tr>
                      <tr>
                        <th scope="row">Bid Time</th>
                        <td>
                          {convertIsoToCustomFormat(bidDetails?.bidTime)}
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}


<div className="col-sm-7">
                  <table className="modal-view table table-bordered bidDetailsModal ">
                    <tbody>
                      <tr>
                        <th scope="row" colspan="2" className="paymentDetails">
                          Product Details
                        </th>
                      </tr>
                      <tr>
                        <th scope="row">Product Name</th>
                        <td>{bidDetails?.productId?.productName}</td>
                      </tr>

                      <tr>
                        <th scope="row">Customer Name</th>
                        <td>
                          {bidDetails?.customerId?.firstName}{" "}
                          {bidDetails?.customerId?.lastName}
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Customer Address</th>
                        <td>{bidDetails?.customerId?.address}</td>
                      </tr>

                      <tr>
                        <th scope="row">Bid Status</th>
                        <td>
                          <span
                            className={`badge ${getOrderStatusColor(
                              bidDetails.orderStatus
                            )}`}
                          >
                            {bidDetails.orderStatus}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Payment Status</th>
                        <td>
                          <span
                            className={`badge ${getPaymentStatusColor(
                              bidDetails.paymentStatus
                            )}`}
                          >
                            {bidDetails.paymentStatus}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Bid Date Time</th>
                        <td>
                          {convertIsoToCustomFormat(bidDetails?.orderTime)}
                          {/* {new Date(bidDetails?.orderTime).toLocaleString()} */}
                        </td>
                      </tr>

                      <tr>
                        <th scope="row" colspan="2" className="paymentDetails">
                          Payment Details
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: "50%" }}>Subtotal:</th>
                        <td>${bidDetails?.bidAmount}</td>
                      </tr>
                      <tr>
                        <th>
                          Treasuretrad Commission (
                          {bidDetails?.premiumType === "flat"
                            ? "Flat"
                            : "10%"}
                          )
                        </th>
                        <td>${bidDetails?.premiumAmount}</td>
                      </tr>

                      <tr>
                        <th>
                          <h6>Total:</h6>
                        </th>
                        <td>
                          <h6>${totatBillAmount} </h6>(
                          {amountToWords(totatBillAmount)})
                        </td>
                      </tr>

                      
                        <>
                          <tr  className="pendingAmount">
                            <th>
                              <span className="green">Paid Amount:</span>
                            </th>
                            <td>
                              <span className="green">${bidDetails?.paidAmount}</span>
                            </td>
                          </tr>
                          <tr className="pendingAmount">
                            <th>
                              <h6 className="red">Balance Amount:</h6>
                            </th>
                            <td>
                              <h6 className="red">
                                ${bidDetails?.pendingAmount}
                              </h6>
                              <span className="red">
                                ({amountToWords(bidDetails?.pendingAmount)})
                              </span>
                            </td>
                          </tr>
                        </>
                      
                    </tbody>
                  </table>
                </div>


                
              </div>
              
            </div>
            {/* <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyBidsComponent;
