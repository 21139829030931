import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector,useDispatch } from "react-redux";
import DashboardLayout from "../../layouts/WebsiteLayout";
import { setToken } from "../../utils/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import { LOGIN_URL } from "../../utils/Constant";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.token);

  useEffect(() => {
    if (authToken) {
      navigate("/my-account");
    }
  }, [authToken, navigate]);

  const showToast = (loading, message, type) => {
    toast[type](
      <div>
        {loading} {message}
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000, // milliseconds
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'email' ? value.toLowerCase() : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      showToast(true, "Please fill in all required fields", "error");
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      //showToast(true, "In Progress. Please wait..", "info");
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      //console.log("result:::: login:::", result)
      const token = result?.data?.token;
      const user_id = result?.data?.user_id;
      const username = result?.data?.username;
      const userImage = result?.data?.image;
      const isCCFilled = result?.data?.isCCFilled;
      const expiresInDays = result?.data?.expiresInDays;
      

      const usertokenData = {
        token: token,
        user_id: user_id,
        username: username,
        image: userImage,
        isCCFilled: isCCFilled,
        expiresInDays: expiresInDays
      };

      //console.log("usertokenData", usertokenData);
      if (response.ok && token) {
        setLoading(false);
        dispatch(setToken(usertokenData));
        showToast(true, result.msg, "success");
        //setTimeout(() => {
          navigate("/my-account");
        //}, 1000);
      } else {
        showToast(true, result.error, "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setLoading(false);
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <DashboardLayout>
      <section className="register-section pt-110">
        {loading && (
          <div className="overlay">
            <div
              class="spinner-border text-danger"
              style={{ width: "5rem", height: "5rem" }}
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row d-flex justify-content-center text-center">
            <div className="col-12">
              <div className="register-btn-group">
                <Link to="/register" className="register-btn style2">
                  Register
                </Link>
                <Link to="/login" className="login-btn style2">
                  Login
                </Link>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-sm-8 mx-auto">
              <div className="right-form-area">
                <h3>Login Your Account</h3>
                <form className="w-100" onSubmit={handleSubmit}>
                  <div className="form-inner">
                    <label>
                      Email  / Username<span className="error">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Email / Username"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-inner">
                    <label>
                      Password <span className="error">*</span>
                    </label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    <i
                          class={`fas ${
                            passwordVisible ? "fa-eye-slash" : "fa-eye"
                          }`}
                          onClick={togglePasswordVisibility}
                        />
                  </div>
                  <div className="form-inner d-flex justify-content-between flex-wrap">
                    <Link to="/forget/password" className="forgot-pass">
                      Forgotten Password
                    </Link>
                  </div>
                  <button className="account-btn" disabled={loading}>
                    {loading ? "Trying to login" : "Login"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default Login;
