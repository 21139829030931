// App Base URL
//let APP_BASE_URL = "http://localhost:5000"
let APP_BASE_URL = "https://api.treasuretrad.com:5000"
export const APP_URL = APP_BASE_URL;


//----------------------------------------------------CUSTOMER - LOGIN URLS--------------------------------------------------
// LOGIN API URL
export const LOGIN_URL = APP_BASE_URL + "/login";

// Forget Api Url
export const FORGET_URL = APP_BASE_URL + "/forget/password"

// Update Api Url
export const UPDATE_URL = APP_BASE_URL + "/update/password"

// Reset Password Api Url
export const RESET_PASSWORD_URL = APP_BASE_URL + "/customer/reset/password"

// Customer Profile Api Url
export const CUSTOMER_PROFILE_URL = APP_BASE_URL + "/profile"

// Customer Profile Update Api Url
export const CUSTOMER_UPDATE_PROFILE_URL = APP_BASE_URL + "/customer/update/profile"

// Activate Account Api Url
export const ACTIVATE_ACCOUNT_URL = APP_BASE_URL + "/activate/account"

// Activate Account Api Url
export const USER_UPDATE_PAYMENT_URL = APP_BASE_URL + "/update/payment"

//----------------------------------------------------CUSTOMER - LOGIN URLS--------------------------------------------------


// Credit Card Details
export const VERIFY_CREDIT_CARD_URL = APP_BASE_URL + "/customer/payment/store/credit/card"

// Credit Card Details
export const VOID_CREDIT_CARD_PAYMENT_URL = APP_BASE_URL + "/customer/payment/void/payment"

// Credit Card Details
export const CREDIT_CARD_URL = APP_BASE_URL + "/customer/update/creditcard/details"




//----------------------------------------------------CUSTOMER - REGISTER URLS --------------------------------------------------
// REGISTER API URL
export const REGISTER_URL = APP_BASE_URL + "/register";




//----------------------------------------------------CUSTOMER - PRODUCTS URL------------------------------------------------------
// Product List URL
export const PRODUCT_LIST_URL = APP_BASE_URL + "/customer/products/list";

// Product Detals URL
export const PRODUCT_DETAILS_URL = APP_BASE_URL + "/customer/products";

// Product Image URL
export const PRODUCT_IMAGE_URL = APP_BASE_URL + "/uploads/products";

// Product List URL
export const PRODUCT_PAST_AUCTIONS_LIST_URL = APP_BASE_URL + "/customer/products/past/auctions";


//----------------------------------------------------CUSTOMER - ORDERS URL-----------------------------------------------------
// Order Create URL
export const ORDER_CREATE_URL = APP_BASE_URL + "/customer/order/create";

// Orders List URL 
export const ORDER_LIST_URL = APP_BASE_URL + "/customer/order/list"

// Orders Detail URL
export const ORDER_DETAIL_URL = APP_BASE_URL + "/customer/order"



//----------------------------------------------------CUSTOMER - BIDS URL ------------------------------------------------------
// BIDS LIST URL
export const BIDS_LIST_URL = APP_BASE_URL + "/customer/bids/list"

// BIDS Create URL
export const BIDS_CREATE_URL = APP_BASE_URL + "/customer/bids/create"

// BIDS Detail URL
export const BIDS_DETAIL_URL = APP_BASE_URL + "/customer/bids"



//----------------------------------------------------    USERS URL   ------------------------------------------------------

// User Image URL
export const USER_IMAGE_URL = APP_BASE_URL + "/uploads/users";

// User Profile URL
export const USER_PROFILE_URL = APP_BASE_URL + "/profile";



//----------------------------------------------------   Dashboard Count  URL   ------------------------------------------------------

// Dashboard Count URL
export const DASH_COUNT_URL = APP_BASE_URL + "/customer/dashboard/counts";

//----------------------------------------------------   General Setting  URL   ------------------------------------------------------

// General Setting URL
export const GENERAL_SETTING_GET_URL = APP_BASE_URL + "/customer/general/settings";

