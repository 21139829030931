// Page.js

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from "react-redux";
import { generalSettingData } from '../../utils/Helper';

const Page = ({ title, description, children }) => {
    const [generalS, setGeneralS] = useState([]);
    const authToken = useSelector((state) => state.token);

    useEffect(() => {
        const fetchLists = async () => {
            try {
                const [categoryData] = await Promise.all([
                    generalSettingData(authToken),
                ]);
                setGeneralS(categoryData);
            } catch (error) { }
        };
        fetchLists();
    }, []);
    //console.log("generalS>>>>>>>>>>>>>", generalS?.data?.seo?.keywords.join(','))


    return (
        <div>
            <Helmet>
                <title>{generalS?.data?.siteTitle}</title>
                <meta name="description" content={generalS?.data?.seo?.metaDescription} />
                <meta name="keywords" content={generalS?.data?.seo?.keywords.join(',')} />
            </Helmet>
            {children}
        </div>
    );
};

export default Page;
