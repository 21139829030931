import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/WebsiteLayout";
import { PRODUCT_LIST_URL, PRODUCT_IMAGE_URL } from "../../utils/Constant";
import SkeletonCard from "../../components/SkeletonCard";

const Home = () => {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchProducts() {
    try {
      const response = await fetch(PRODUCT_LIST_URL);
      const json = await response.json();
      setProductData(json.data);
    } catch (err) {
      console.error("Error fetching products:", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <DashboardLayout>
      <>
        <div className="container live-auction-area">
          <div className="row">
          <div className="col-lg-12">
              <div className="section-header-two taglineContainer">
              <span className="tagline">A place to Buy or Sell neighborhood treasure</span>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="section-header-two">
                <h3>Live Auction</h3>
              </div>
            </div>
          </div>
          <div className="row g-4">
            {loading ? (
              <SkeletonCard />
            ) : productData.length > 0 ? (
              productData.map((item) => (
                <div className="col-lg-4 col-md-6" key={item._id}>
                  <div className="product-card product-beta-md">
                    <div className="product-header">
                      <h6 className="product-title">{item.productName}</h6>
                      <h4 className="price">{getPricingText(item)}</h4>
                    </div>
                    <div className="product">
                    <a href={`/products/${item._id}`} className="bid-btn">
                      
                      <img
                        src={`${PRODUCT_IMAGE_URL}/${item.featuredImage}`}
                        alt={item.productName}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                        }}
                      />
                      </a>
                    </div>
                    <div className="product-disc">
                    <a href={`/products/${item._id}`} className="bid-btn">
                        {getButtonText(item)}
                      </a>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-lg-12 error-wrapper-start">
                <div className="container">
                  <div className="col-lg-12">
                    <div className="error-content text-center">
                      <div className="error-texts mx-auto">
                        <h5 className="themeTextColor">
                          Keep an Eye Out for Our Next Round of Thrilling
                          Auctions!
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="container win-process-area mt-110 mb-50">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-header-two text-center">
                <h3>Easy 03 Steps To Win</h3>
              </div>
            </div>
          </div>
          <div className="row g-4 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-process">
                <span className="corner-1"></span>
                <span className="corner-2"></span>
                <div className="icon mx-auto">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/dist/img/process1.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="process-disc">
                  <h4>
                    <span>Log in / Sign up</span>
                  </h4>
                  <p>Credit Card Required</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-process">
                <span className="corner-1"></span>
                <span className="corner-2"></span>
                <div className="icon mx-auto">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/dist/img/process2.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="process-disc">
                  <h4>
                    <span>Bid Now</span>
                  </h4>
                  <p>10% Auto Debit Only If You Win</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-process">
                <span className="corner-1"></span>
                <span className="corner-2"></span>
                <div className="icon mx-auto">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/dist/img/process3.svg"
                    }
                    alt=""
                  />
                </div>
                <div className="process-disc">
                  <h4>
                    <span>Win</span>
                  </h4>
                  <p>Fun - Excitement - Great Deals</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid newslatter-area newslatter-style-one mt-110">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="newslatter-content text-center">
                <h2>
                  Sign Up for our Newsletter to Stay Up to Date With New
                  Auctions!
                </h2>
                <form action="#" id="newslatter_form">
                  <div className="newslatter-input d-flex flex-sm-nowrap flex-wrap justify-content-center align-items-center gap-md-4 gap-2">
                    <input
                      type="text"
                      placeholder="Enter Your Email Address"
                      required
                    />
                    <button type="submit" className="text-uppercase">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </>
    </DashboardLayout>
  );
};

const getPricingText = (item) => {
  const isBidExpired = +new Date() > +new Date(item.bidEndDate);

  if (item.sellingType === "both" || item.sellingType === "bidnow") {
    if (item.isOrdered) {
      return `Sold for $${item.orderDetails.orderAmount.toFixed(0)}`;
    } else if (isBidExpired && item.isBidWon) {
      return `Winning Bid: $${item.bidsDetails[0].bidAmount.toFixed(0)}`;
    } else if (!isBidExpired && item.bidsDetails.length === 0) {
      return `Starting Bid: $${item.lowestPriceAccept}`;
    } else if (isBidExpired) {
      return `Auction Expired`;
    } else {
      return `Current Bid: $${item.bidsDetails[0].bidAmount.toFixed(0)}`;
    }
  } else if (item.sellingType === "buynow") {
    return item.isOrdered ? `Sold for $${item.buyNowPrice}` : `$${item.buyNowPrice}`;
  }
};

const getButtonText = (item) => {
  const isBidExpired = +new Date() > +new Date(item.bidEndDate);
console.log("isBidExpired",isBidExpired)
  if (item.sellingType === "both" || item.sellingType === "bidnow") {
    if (item.isOrdered) {
      return "Sold Out";
    }else if (item.isBidWon) {
      return "Bid Won"; // Or any appropriate text for the winning bid
    } else if (isBidExpired) {
      return "Expired";
    }else if (item.sellingType === "both") {
      return "Bid & Buy";
    }  else {
      return "Bid Now";
    }
  } else if (item.sellingType === "both" || item.sellingType === "buynow" ) {
    return item.isOrdered ? "Sold Out" : "Buy It Now";
  }
};


export default Home;
