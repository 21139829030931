import React, { useState } from 'react'
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RESET_PASSWORD_URL } from '../../utils/Constant';

const ChangePassword = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false); // State to manage loading state
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: ""
    });
    const authToken = useSelector((state) => state.token);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const showToast = (loading, message, type) => {
        toast[type](
            <div>
                {loading} {message}
            </div>,
            {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, // milliseconds
            }
        );
    };

    const passwordsMatch = () => {
        return formData.password === formData.confirmPassword;
    };

    const areAllFieldsFilled = () => {
        for (const key in formData) {
            if (!formData[key]) {
                return false;
            }
        }
        return true;
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!passwordsMatch()) {
            showToast(true, "Passwords do not match", "error");
            return;
        }
        let data = {
            "newPassword": formData.password
        }
        if (!areAllFieldsFilled()) {
            showToast(true, "Please fill all the details", "error");
        } else {
            try {
                setLoading(true); // Show spinner
                //showToast(true, "In Progress. Please wait..", "info");

                const response = await fetch(RESET_PASSWORD_URL, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken.token}`,
                    },
                    credentials: "include",
                    body: JSON.stringify(data),
                });
                const result = await response.json();

                if (response.ok) {
                    showToast(true, result.message, "success");
                    setFormData({
                        password: "",
                        confirmPassword: ""
                    })
                    setTimeout(() => {
                        // navigate("/login");
                    }, 1000);
                } else {
                    showToast(true, result.error, "error");
                    setLoading(false); // Show spinner
                    console.error("Registration failed:", result.message);
                }
                setLoading(false); // Show spinner
            } catch (error) {
                showToast(true, error.message, "error");
                setLoading(false); // Show spinner
                console.error("Error during registration:", error.message);
            }
        }
        // }
    };


    return (
        <div className="login">
            <div className="login-form-container">
                <div className="account-login-form">
                    {loading && (
                        <div className="overlay">
                            <div
                                class="spinner-border text-danger"
                                style={{ width: "5rem", height: "5rem" }}
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                    <form action="#" onSubmit={handleSubmit}>
                        <div className="account-input-box">
                            <label>New Password</label>
                            <input
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange} />
                            <i
                                className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"
                                    }`}
                                onClick={togglePasswordVisibility}
                            />
                        </div>
                        <div className="account-input-box">
                            <label>Confirm New Password</label>
                            <input
                                type={confirmPasswordVisible ? "text" : "password"}
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleChange} />
                            <i
                                className={`fas ${confirmPasswordVisible ? "fa-eye-slash" : "fa-eye"
                                    }`}
                                onClick={toggleConfirmPasswordVisibility}
                            />
                        </div>

                        <div className="button-box">
                            <button
                                className="btn default-btn"
                                type="submit"
                            >
                                Update Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword