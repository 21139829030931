import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CUSTOMER_PROFILE_URL,
  CUSTOMER_UPDATE_PROFILE_URL,
  USER_IMAGE_URL,
} from "../../utils/Constant";
import MyAcccountCSS from "./MyAccount.css";

const AccountDetails = () => {
  const [customerImage, setCustomerImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");

  const authToken = useSelector((state) => state.token);
  const showToast = (message, type) => {
    toast[type](message, {
      position: toast.POSITION.TOP_CENTER, // Change position to top center
      autoClose: 2000,
    });
  };

  const fetchUserData = async () => {
    if (authToken) {
      setLoading(true); // Show spinner
      try {
        const response = await fetch(CUSTOMER_PROFILE_URL, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken.token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setFirstName(data.data.firstName || "");
        setLastName(data.data.lastName || "");
        setEmail(data.data.email || "");
        setUserName(data.data.username || "");
        setPhone(data.data.phone || "");
        setAddress(data.data.address || "");
        setPincode(data.data.pinCode || "");
        setCustomerImage(data.data.image || "");
        setImagePreview(
          data.data.image ? `${USER_IMAGE_URL}/${data.data.image}` : null
        );
        setLoading(false); // Show spinner
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchUserData();
    }
  }, [authToken]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCustomerImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("username", userName);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("pinCode", pincode);

    formData.append("image", customerImage);

    try {
      setLoading(true); // Show spinner
      const response = await fetch(CUSTOMER_UPDATE_PROFILE_URL, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authToken.token}`,
        },
        body: formData,
      });
      const result = await response.json();
      if (response.ok) {
        showToast(result.message, "success");
        fetchUserData();
      } else {
        showToast(result.message, "error");
      }
    } catch (error) {
      console.error("Error during login:", error);
      showToast("An error occurred", "error");
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  //console.log("userProfileData:::::", userProfileData);
  return (
    <div className="login">
      <div className="login-form-container">
        <div className="account-login-form">
          {loading && (
            <div className="overlay">
              <div
                class="spinner-border text-danger"
                style={{ width: "5rem", height: "5rem" }}
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <form action="#" onSubmit={handleSubmit}>
            <div className="account-input-box">
              <label>First Name</label>
              <input
                type="text"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <label>Last Name</label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={email}
                readonly="readonly"
                disabled
              />
              <label>Username</label>
              <input
                type="text"
                name="userName"
                value={userName}
                readonly="readonly"
                disabled
              />
              <label>Phone</label>
              <input
                type="number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <label>Address</label>
              <input
                type="text"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
              />
              <label>Pin Code</label>
              <input
                type="number"
                name="user-password"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
              <div className="form-group">
                <label htmlFor="profileImage">Profile Picture</label>
                <input
                  type="file"
                  className="form-control-file"
                  id="profileImage"
                  name="profileImage"
                  onChange={handleImageChange}
                />
                {/* Display image preview */}
                {imagePreview ? (
                  <div>
                    <br></br>
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{ borderRadius: "50%", height: "200px" }}
                    />
                  </div>
                ) : (
                  <div>
                    <br></br>
                    <img
                      src={`${USER_IMAGE_URL}/${customerImage}`}
                      alt="Preview"
                      style={{ borderRadius: "50%", height: "200px" }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="button-box">
              <button className="btn default-btn" type="submit">
                Update Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
