import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/WebsiteLayout";
import { ACTIVATE_ACCOUNT_URL } from "../../utils/Constant";

const ActivateAccount = () => {
  const [activationMsg, setActivationMsg] = useState("");
  const [isActivation, setIsActivation] = useState(false);
  const { activationcode } = useParams();

  useEffect(() => {
    if (activationcode) {
      activateAccount(activationcode);
    } else {
      setActivationMsg("Activation code not provided");
      setIsActivation(false);
    }
  }, [activationcode]);

  const getUsernameFromString = (str) => {
    const startIndex = str.indexOf("&username=") + "&username=".length;
    const username = str.substring(startIndex).split("&")[0];
    return username;
  };
  const username = getUsernameFromString(activationcode);
  const activateAccount = async (activationCode) => {
    try {
      const response = await fetch(ACTIVATE_ACCOUNT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
            username:username,
          activationCode: activationCode,
        }),
      });
      const result = await response.json();

      //console.log("result", result);

      if (response.ok) {
        //console.log("Log 1");
        setIsActivation(true);
        setActivationMsg(result.message);
      } else {
        //console.log("Log 2");
        setIsActivation(false);
        setActivationMsg(result.message);
      }
    } catch (error) {
      //console.log("Log 3");
      setActivationMsg("Error during Activation:");
      console.error("Error during Activation:", error);
      setIsActivation(false);
    }
  };

 

  return (
    <DashboardLayout>
      <div>
        {!activationMsg && (
          <div className="overlay">
            <div
              className="spinner-border text-danger"
              style={{ width: "5rem", height: "5rem" }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        <div className="classy-box">
          <div className="product-card product-beta-md">
            <div className="product-disc">
              <br />
              <br />
              <br />
              <p style={{ fontSize: "16px" }}>
                <b style={{ fontSize: "24px", color: "#c21557" }}>
                  {isActivation
                    ? `Congratulations ${username}!`
                    : `Hi, ${username}!`}
                </b>
                <br />
                <br />
                <p style={{color:"green",fontWeight:"bold"}}>{activationMsg ? activationMsg : "Please wait..."}</p>
                <br />
              </p>
              {isActivation && (
                <>
                  <p>
                    {" "}
                    You're now ready to dive into the world of
                    thrilling auctions.
                  </p>
                  <br />
                  <p>
                    Start exploring our wide range of vehicles, parts,
                    and accessories, and get ready to bid on your dream items.
                  </p>
                  <br />
                  <p>
                    <b>Happy bidding!</b>
                  </p>
                  <br />
                  <br />
                  <a href="/login" className="bid-btn">
                    Login Now
                  </a>
                  <br />
                </>
              )}
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ActivateAccount;
