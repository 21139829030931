import React from "react";
import DashboardLayout from "../../layouts/WebsiteLayout";

const About = () => {
  const imagePath =
    process.env.PUBLIC_URL + "/assets/dist/img/about-breadcrumb-bg.png";
  return (
    <DashboardLayout>
      <div
        className="breadcrumb breadcrumb-style-one mb-0"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 1, 13, 0.8), rgba(0, 1, 13, 0.8)), url(${imagePath})`,
        }}
      >
        <div className="container">
          <div className="col-lg-12 text-center">
            <h1 className="breadcrumb-title">PHILOSOPHY</h1>
            <ul className="d-flex justify-content-center breadcrumb-items">
              <li className="breadcrumb-item">
                <i className="bi bi-house-door" /> <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active">Philosophy</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="about-wrpper pt-50">
        <div className="container">
          <p
            style={{
              fontWeight: "bold",
              color: "#c21557",
              fontSize: "16px !important",
              fontStyle: "italic",
            }}
          >
            Philosophy
          </p>

          <p>
            We use online technology to help clients clear out their
            collectibles and home treasures for quick $$. It could be an
            individual/business in financial distress, an Estate Sale, or fun
            way to make money. At Treasuretrsd we connect the public to Treasure
            found nowhere else.
          </p>

          <p>
            If you are a seller wanting to clear out your valuables then our
            Auction is for you! We will immediately send an associate to you,
            and make sure we help you liquidate fast by getting your items to
            the best selling condition possible. <br></br>Text us at:{" "}
            <span style={{ color: "#c21557", fontWeight: "bold" }}>
              Phone <a href="tel:+7604014871">(760) 401 4871</a>
            </span>
          </p>

          <p>
            If you have any collectibles or Treasure such as: coins jewelry
            crystals art pieces precious metals and you need to get rid of them
            please text us at{" "}
            <span style={{ color: "#c21557", fontWeight: "bold" }}>
              <a href="tel:+7604014872">(760) 401 4872</a>
            </span>
            , and or e-mail us at{" "}
            <span style={{ color: "#c21557", fontWeight: "bold" }}>
              <a href="mailto:partzcheap@gmail.com">partzcheap@gmail.com</a>
            </span>
            . We can auction them off or even send someone to pick them up now!
            All we ask is that you send us at least one picture, a description.
            Make sure to let us know any pertinent information. Sell it to today
            by clicking “sell your item” above.
          </p>

          <p
            style={{
              fontWeight: "bold",
              color: "#c21557",
              fontStyle: "italic",
            }}
          >
            Promise To Work with Integrity and Transparency
          </p>

          <p>
            We work to create a network for the Public to find deals found
            nowhere else. We are focused on maintaining the highest “integrity.”
            In the event we make a mistake we guarantee to fix it. We use the
            highest security when collecting payments and keeping your data. We
            are a San Diego, CA business that is viable now and will be well
            into the future. Rest assured our auctions are accurate, secure, and
            a platform made to benefit users beyond what is available on any
            other auction website.
          </p>
          <p>
            Thank you for shopping with Treasuretrad and for being an important
            part of our great network of Buyers and Sellers.
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default About;
