import React from "react";
import { USER_IMAGE_URL } from "../../utils/Constant";

const ProductBidHistory = ({ allBids }) => {
  //console.log("allBids:::::::::::::", allBids);
  return (
    <div className="single-widget mb-3">
      <h5 className="wdget-title">Bidding History</h5>
      <div>
        {allBids.length === 0 ? (
          <div className="bidding-item gap-sm-4 gap-2">
            <div className="bidding-content">
              <p>No bids available</p>
            </div>
          </div>
        ) : (
          <div id="biddingContainer" className="bidding-container">
            {allBids.map((bid) => (
              <div key={bid._id} className="bidding-item gap-sm-4 gap-2 ">
                <div className="bidding-img">
                  <img
                    src={`${USER_IMAGE_URL}/${bid?.customerId?.image}`}
                    alt=""
                  />
                </div>
                <div className="bidding-content">
                  <h6>
                    {bid.customerId?.firstName} {bid?.customerId?.lastName}
                  </h6>
                  <p>
                    Bid {calculateTimeDifference(bid.bidTime)} ago for{" "}
                    <b>${bid.bidAmount}</b>
                  </p>
                </div>
                <div className={`bidding-result ${
                      bid.isWinner && bid.bidStatus === "completed"
                        ? "winner"
                        : !bid.isWinner && bid.bidStatus === "end"
                        ? "loser"
                        : "ongoing"
                    }`}>
                  <span
                    
                  >
                    <i
                      className={`fa  ${
                        bid.isWinner && bid.bidStatus === "completed"
                          ? "fa-regular fa-circle-check"
                          : !bid.isWinner && bid.bidStatus === "end"
                          ? "fa-regular fa-circle-xmark"
                          : ""
                      }`}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
const calculateTimeDifference = (bidTime) => {
  const currentTime = new Date();
  const bidDateTime = new Date(bidTime);
  const timeDifference = Math.floor((currentTime - bidDateTime) / (1000 * 60)); // in minutes

  if (timeDifference < 60) {
    return `${timeDifference} minutes`;
  } else if (timeDifference < 1440) { // Less than 24 hours
    const hours = Math.floor(timeDifference / 60);
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (timeDifference < 525600) { // Less than a year (525600 minutes)
    const days = Math.floor(timeDifference / 1440);
    return `${days} day${days > 1 ? 's' : ''}`;
  } else {
    const years = Math.floor(timeDifference / 525600);
    return `${years} year${years > 1 ? 's' : ''}`;
  }
};

export default ProductBidHistory;
