import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DashboardLayout from '../../layouts/WebsiteLayout';
import { UPDATE_URL } from '../../utils/Constant';
import { useNavigate, useParams } from "react-router-dom";

const UpdatePassword = () => {
    const [formData, setFormData] = useState({ password: "", confirmPassword: "" });
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const activationCode = params.activationCode;

    const showToast = (message, type) => {
        toast[type](message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000, // milliseconds
        });
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const passwordsMatch = () => {
        return formData.password === formData.confirmPassword;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!passwordsMatch()) {
            showToast("Passwords do not match", "error");
            return;
        }

        let data = {
            "activationCode": activationCode,
            "newPassword": formData.password
        };

        //console.log("data",data)

        try {
            setLoading(true);
            showToast("In Progress. Please wait..", "info");
            const response = await fetch(UPDATE_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.ok) {
                setLoading(false);
                showToast(result.message, "success");
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                showToast(result.message, "error");
                setLoading(false);
            }
        } catch (error) {
            console.error("Error during login:", error);
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <section className="register-section pt-110">
                <div className="container">
                    <div className="row d-flex justify-content-center text-center">
                        <div className="col-12">
                            <div className="register-btn-group">
                                <Link className="login-btn style2">
                                    Update Password
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-4">
                        {/* <div className="col-lg-6">
                            <div className="register-left-area style2">
                                <h3>Hello Again</h3>
                                <p>Welcome Back You’ve Been Missed</p>
                                <Link to="/" className="transparent-btn">
                                    Back Home
                                </Link>
                            </div>
                        </div> */}
                        <div className="col-sm-8 mx-auto">
                            <div className="right-form-area">
                                <h3>Update Your Password</h3>
                                <form className="w-100" onSubmit={handleSubmit}>
                                    <div className="form-inner">
                                        <label>New Password <span className="error">*</span></label>
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            id="password"
                                            placeholder="Password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                        />
                                        <i
                                            className={`fas ${passwordVisible ? "fa-eye-slash" : "fa-eye"
                                                }`}
                                            onClick={togglePasswordVisibility}
                                        />
                                    </div>
                                    <div className="form-inner">
                                        <label>Confirm Password <span className="error">*</span></label>
                                        <input
                                            type={confirmPasswordVisible ? "text" : "password"}
                                            id="confirmPassword"
                                            placeholder="Confirm Password"
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            required
                                        />
                                        <i
                                            className={`fas ${confirmPasswordVisible ? "fa-eye-slash" : "fa-eye"
                                                }`}
                                            onClick={toggleConfirmPasswordVisibility}
                                        />
                                    </div>
                                    <button className="account-btn" disabled={loading}>
                                        {loading ? "Updating Password...." : "Update Password"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </DashboardLayout>
    );
}

export default UpdatePassword;
