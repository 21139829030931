import React from 'react';
import DashboardLayout from '../layouts/WebsiteLayout'

function NotFoundPage() {
    const imagePath = process.env.PUBLIC_URL + "/assets/images/shape/404-vactor.png";

    return (
        <DashboardLayout>
           

    <div class="error-wrapper-start mt-110 mb-3">
      <div class="container">
        <div class="col-lg-12">
          <div class="error-content text-center">
            <div class="error-illustration">
              <img
                src={imagePath}
                alt
                class="img-fluid"
              />
            </div>
            <div class="error-texts mx-auto">
              <h2>Oops... Page Not Found!</h2>
              <p>
                The page you are looking for may have been renamed or does not
                exist in this server you will be redirected to homepage shortly
              </p>
              <div class="back-btn">
                <a href="/" class="alpha-btn-md-dark">Back Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </DashboardLayout>
    )
}

export default NotFoundPage;
