import React from "react";

const SkeletonCard = () => {
  return Array(4)
    .fill()
    .map((item, index) => (
      <div class="col-lg-3 col-md-6" key={index}>
        <div class="shimmerCard product-card">
          <div class="shimmer">
          <div class="details">
              <div class="title"></div>
              <div class="price"></div>
            </div>
            <div class="image"></div>
            
            <div class="bidNowBtn">
              <span class="btn btn-default"></span>
            </div>
          </div>
        </div>
      </div>
    ));
};

export default SkeletonCard;
